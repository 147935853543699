<template>
  <span>
    <span class="pointer" @click="value = !value">
      {{ $t('login.imprint') }}
    </span>
    <styled-dialog v-model="value" :header="$t('login.imprint')">
      <div class="subtitle-2 text-uppercase text-center font-weight-bold my-6">
        <span class="letter-spacing-3">Mit freundlicher Unterstützung von</span>
      </div>

      <v-layout align-center justify-center wrap>
        <v-flex :xs12="$vuetify.breakpoint.xsOnly" class="pa-6 text-center">
          <img :src="require('@/shared/assets/logos/nrw.svg')" height="50" alt="Logo NRW" />
        </v-flex>
        <v-flex :xs12="$vuetify.breakpoint.xsOnly" class="pa-6 text-center">
          <img :src="require('@/shared/assets/logos/gorheinland.svg')" height="35" alt="Logo go.Rheinland" />
        </v-flex>
        <v-flex :xs12="$vuetify.breakpoint.xsOnly" class="pa-6 text-center">
          <img :src="require('@/shared/assets/logos/nwl.svg')" height="35" alt="Logo NWL" />
        </v-flex>
        <v-flex :xs12="$vuetify.breakpoint.xsOnly" class="pa-6 text-center">
          <img :src="require('@/shared/assets/logos/vrr.svg')" height="55" alt="Logo VRR" />
        </v-flex>
      </v-layout>

      <v-layout class="highlight grey lighten-2 px-6 py-12 my-6">
        <v-flex>
          <h1 class="display-1 text-center text-uppercase mb-4">
            <span class="letter-spacing-3">{{ $t('login.imprint') }}</span>
          </h1>

          <h3 class="subtitle-1 text-center line-height-1-25 mb-12">
            Das Kompetenzcenter Sicherheit ist eine Einrichtung beim
            <br />
            Verkehrsverbund Rhein-Ruhr.
          </h3>

          <span class="body-1 font-weight-bold text-uppercase">
            <span class="letter-spacing-2">Verkehrsverbund<br />Rhein-Ruhr AöR</span>
          </span>
          <br />
          Augustastraße 1
          <br />
          45879 Gelsenkirchen
          <br />
          <br />

          Telefon: +49 209 1584-0
          <br />
          Telefax: +49 209 23967
          <br />
          E-Mail: info@vrr.de
          <br />
          Internet:
          <a href="https://www.vrr.de" target="_blank" rel="noopener"> www.vrr.de </a>
        </v-flex>
      </v-layout>

      <h3 class="body-1 font-weight-bold text-uppercase pt-6 mb-4">
        <span class="letter-spacing-2">Vorstand:</span>
      </h3>
      Gabriele Matz (Sprecherin)
      <br />
      José Luis Castrillo

      <v-divider class="my-12"></v-divider>
      <h3 class="body-1 font-weight-bold text-uppercase mb-6">
        <span class="letter-spacing-2">Handelsregistereintrag:</span>
      </h3>
      Amtsgericht Essen, HRA 8767

      <v-divider class="my-12"></v-divider>
      <h3 class="body-1 font-weight-bold text-uppercase mb-6">
        <span class="letter-spacing-2">Inhaltlich Verantwortliche gemäß §§ 5, 6 TMG und § 55 Abs. 2 RStV:</span>
      </h3>
      Tobias Richert

      <v-divider class="my-12"></v-divider>
      <h3 class="body-1 font-weight-bold text-uppercase mb-6">
        <span class="letter-spacing-2">Konzeption, Gestaltung, Technische Umsetzung:</span>
      </h3>
      move elevator GmbH<br />
      GF: Hans Piechatzek, Markus Lacum, Alexander Hippe<br />
      Zum Aquarium 6a<br />
      46047 Oberhausen<br />
      <a
        href="https://www.move-elevator.de"
        target="_blank"
        rel="noopener"
        title="Werbeagentur und TYPO3-Digitalagentur"
      >
        www.move-elevator.de
      </a>
      <h4 class="body-2 font-weight-bold text-uppercase mb-2 mt-4">
        <span class="letter-spacing-2">Bildquellen:</span>
      </h4>
      Hintergrund-Bild
      <i>
        <a
          href="https://www.freepik.com/free-vector/train-station-buildings_3824529.htm"
          target="_blank"
          rel="noopener"
        >
          "Train station buildings"
        </a>
      </i>
      <br />
      <a href="https://www.freepik.com" target="_blank" rel="noopener">
        original designed by Vectorpouch - Freepik.com
      </a>
      <v-divider class="my-12"></v-divider>
      <h3 class="body-1 font-weight-bold text-uppercase mb-6">
        <span class="letter-spacing-2">Urheberrecht</span>
      </h3>
      Alle Inhalte sind nur zur persönlichen Information bestimmt. Die Inhalte des Internetangebotes dürfen – ganz oder
      auch nur teilweise – ohne die vorherige schriftliche Zustimmung der VRR AöR nicht verändert und nicht (für
      öffentliche oder kommerzielle Zwecke) vervielfältigt, vorgeführt, verbreitet oder anderweitig verwertet werden.
      Für Inhalte, die in den Quellenangaben als von Dritten bezogen gekennzeichnet sind, kann die vorherige
      schriftliche Zustimmung auch bei den jeweiligen Dritten eingeholt werden. Die in diesem Internetangebot
      enthaltenen Inhalte sind urheberrechtlich geschützt. Alle Rechte sind vorbehalten. Alle in diesen Internetseiten
      erwähnten VRR-Produktnamen sowie der Firmenname VRR sind eingetragene Marken.

      <v-divider class="my-12"></v-divider>
      <h3 class="body-1 font-weight-bold text-uppercase mb-6">
        <span class="letter-spacing-2">Nutzungs- und Haftungshinweise</span>
      </h3>
      Die Benutzung dieses Internetangebotes und das Herunterladen von Daten erfolgt auf eigene Gefahr.
      Haftungsansprüche gegen die VRR AöR, die sich auf direkte oder indirekte Schäden materieller oder ideeller Art
      beziehen, welche durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung
      fehlerhafter und unvollständiger Informationen verursacht wurden, sind ausgeschlossen, soweit diese Schäden nicht
      auf Vorsatz und grober Fahrlässigkeit beruhen.

      <v-divider class="my-12"></v-divider>
      <h3 class="body-1 font-weight-bold text-uppercase mb-6">
        <span class="letter-spacing-2">Haftung für Links</span>
      </h3>
      Die Nutzung eines Links von diesem Internetangebot zu der Internetseite eines Dritten, führt zum Verlassen der
      Internetseite der VRR AöR und erfolgt auf Verantwortung des Nutzers. Wir erklären hiermit ausdrücklich, dass zum
      Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Für die Inhalte
      der verlinkten Internetangebote wird keine Verantwortung übernommen. Für den Inhalt der verlinkten Seiten sind
      ausschließlich deren Betreiber verantwortlich.
      <br /><br />
      Da wir auf die zukünftige Gestaltung, die Inhalte oder die Urheberschaft der verlinkten Seiten keinerlei Einfluss
      haben, distanzieren wir uns hiermit auch ausdrücklich von allen Inhalten der verlinkten Seiten, die nach der
      Linksetzung verändert wurden.
      <br /><br />
      Die Internetseiten der VRR AöR können von einer anderen Internetseite verlinkt worden sein. Die VRR AöR übernimmt
      keine Verantwortung für Inhalte, Darstellungen oder irgendeine Verbindung zur VRR AöR in Internetseiten Dritter.
    </styled-dialog>
  </span>
</template>

<script>
import StyledDialog from '@/shared/components/StyledDialog';

export default {
  data() {
    return {
      value: false
    };
  },
  components: {
    StyledDialog
  }
};
</script>

<style scoped>
.highlight {
  margin-left: -24px;
  margin-right: -24px;
}
</style>

<template>
  <v-main
    v-scroll="onScroll"
    :class="{
      background: this.$auth.check() && $vuetify.breakpoint.smAndUp && $route.name.search('reporting|analysis') < 0,
      white: $vuetify.breakpoint.xsOnly
    }"
  >
    <global-snackbar />
    <slot />
  </v-main>
</template>

<script>
import { mapMutations } from 'vuex';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/store';
import GlobalSnackbar from '@/shared/modules/Layout/components/GlobalSnackbar';

export default {
  components: { GlobalSnackbar },
  methods: {
    ...mapMutations(LAYOUT_NAMESPACE, ['setToolbarTitle']),
    onScroll(e) {
      if (typeof window === 'undefined') {
        return;
      }

      const top = window.pageYOffset || e.target.scrollTop || 0;

      this.setToolbarTitle(top > 40);
    }
  }
};
</script>

<style scoped>
.v-main {
  min-height: 100vh;
}
.background {
  background: url('~@/shared/assets/background.png') no-repeat fixed right bottom;
  background-size: auto 60%;
}
</style>

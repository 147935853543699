<template>
  <v-snackbar
    v-model="visible"
    v-bind="$attrs"
    :color="color"
    :class="{
      desktop: $vuetify.breakpoint.smAndUp,
      maxWidth: miniSidebar,
      minHeight: $vuetify.breakpoint.smOnly
    }"
  >
    <v-icon class="mr-3" v-text="icon" v-if="icon" :color="color"></v-icon>
    <slot></slot>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/store';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    color: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(LAYOUT_NAMESPACE, ['miniSidebar']),
    visible: {
      get() {
        return this.value;
      },
      set(visible) {
        this.$emit('input', visible);
      }
    }
  }
};
</script>

<style scoped>
.v-snack {
  padding-top: 58px;
  z-index: 1;
}
.v-snack.desktop {
  padding-left: 230px;
  padding-top: 68px;
}
.v-snack.desktop.minHeight {
  padding-top: 60px;
}
.v-snack.desktop.maxWidth {
  padding-left: 80px;
}
:deep(.v-snack__wrapper) {
  min-width: 90%;
}
.v-snack.desktop >>> .v-snack__wrapper {
  min-width: 344px;
}
:deep(.v-snack__wrapper.primary),
:deep(.v-snack__wrapper.secondary),
:deep(.v-snack__wrapper.error),
:deep(.v-snack__wrapper.warning),
:deep(.v-snack__wrapper.success) {
  background-color: var(--v-white-base) !important;
  color: var(--v-black-base) !important;
}
:deep(.v-snack__wrapper.primary) {
  border-bottom: 3px solid var(--v-primary-base) !important;
}
:deep(.v-snack__wrapper.secondary) {
  border-bottom: 3px solid var(--v-secondary-base) !important;
}
:deep(.v-snack__wrapper.error) {
  border-bottom: 3px solid var(--v-error-base) !important;
}
:deep(.v-snack__wrapper.warning) {
  border-bottom: 3px solid var(--v-warning-base) !important;
}
:deep(.v-snack__wrapper.success) {
  border-bottom: 3px solid var(--v-success-base) !important;
}
:deep(.v-snack__content) {
  text-align: center;
}
</style>

<template>
  <v-row class="fill-height flex-column">
    <v-col cols="auto">
      <v-row>
        <v-col cols="auto" class="ml-8 pt-8">
          <v-avatar color="primary" class="white--text font-weight-medium">
            {{ publisher }}
          </v-avatar>
        </v-col>
        <v-col class="pt-8">
          <v-row>
            <v-col cols="4" class="py-0">
              <v-select
                v-model="publisher"
                :label="$t('news.from')"
                :items="publisherItems"
                data-cy="publisherSelect"
                append-icon="fal fa-angle-down"
              ></v-select>
            </v-col>
            <v-col cols="8" class="py-0">
              <v-select
                v-model="receivers"
                :label="$t('news.to')"
                :items="translatedReceivers"
                data-cy="receiversSelect"
                append-icon="fal fa-angle-down"
                multiple
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-text-field v-model="subject" :label="$t('news.subject')" data-cy="subjectTextField" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="pr-6">
          <v-btn :to="{ name: 'news' }" color="primary" data-cy="cancelButton" exact icon>
            <v-icon> fal fa-times </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="overlay-wrapper py-0">
      <editor v-model="content" :init="editorConfig" ref="tinymce" id="tinymce" />
      <v-row v-if="editorLoading" class="overlay fill-height mx-6" align="center">
        <v-col class="text-center">
          <v-progress-circular color="primary" indeterminate />
        </v-col>
      </v-row>
      <v-btn
        @click="$emit('submit')"
        :loading="loading"
        :disabled="isSubmitDisabled"
        color="secondary"
        class="ma-12"
        data-cy="submitButton"
        x-large
        fixed
        bottom
        right
        fab
      >
        <v-icon>fal fa-save</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';
import imageCompression from 'browser-image-compression';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      publisherItems: ['KCS', 'NWL', 'NVR', 'm:e'],
      receiverItems: [
        'SUPER_ADMINISTRATOR',
        'SYSTEM_ADMINISTRATOR',
        'ADMINISTRATOR',
        'REPORTER',
        'EVALUATOR',
        'DATA_USER'
      ],
      formData: {
        subject: '',
        publisher: '',
        receivers: '',
        content: ''
      },
      editorLoading: false,
      editorConfig: {
        language: 'de',
        plugins: 'autoresize, lists, link, table, hr',
        menubar: false,
        statusbar: false,
        toolbar:
          'fontsizeselect forecolor bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customimage table hr | undo redo',
        content_css: false,
        content_style: [contentCss, contentUiCss].join('\n'),
        paste_data_images: true,
        setup: (editor) => {
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/jpeg,image/png');
          input.setAttribute('style', 'display: none');
          input.setAttribute('data-cy', 'imageUploadField');
          input.onchange = async () => {
            if (!input.files[0]) {
              return;
            }
            this.editorLoading = true;
            const uploadedFile = input.files[0];
            const compressedFile = await imageCompression(uploadedFile, {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true
            });
            const compressedFileUrl = await imageCompression.getDataUrlFromFile(compressedFile);
            editor.insertContent('<img src="' + compressedFileUrl + '" alt="" />');
            input.value = '';
            this.editorLoading = false;
          };
          document.body.append(input);

          editor.ui.registry.addButton('customimage', {
            icon: 'image',
            tooltip: this.$t('news.newsForm.image'),
            onAction: () => {
              input.click();
            }
          });

          editor.on('drop', async (event) => {
            if (false === ['image/jpeg', 'image/png'].includes(event.dataTransfer.files[0].type)) {
              return;
            }
            this.editorLoading = true;
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
            const droppedFile = await imageCompression.getFilefromDataUrl(
              await imageCompression.getDataUrlFromFile(event.dataTransfer.files[0]),
              String(new Date().getTime())
            );
            const compressedFile = await imageCompression(droppedFile, {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true
            });
            const compressedFileUrl = await imageCompression.getDataUrlFromFile(compressedFile);
            editor.insertContent('<img src="' + compressedFileUrl + '" alt="" />');
            this.editorLoading = false;
          });
        }
      }
    };
  },
  computed: {
    translatedReceivers() {
      return this.receiverItems.map((receiver) => ({
        text: this.$t(`news.receiver.${receiver.toLowerCase()}`),
        value: receiver
      }));
    },
    isSubmitDisabled() {
      return '' === this.publisher || 0 === this.receivers.length || '' === this.subject || '' === this.content;
    },
    publisher: {
      get() {
        return this.value.publisher;
      },
      set(publisher) {
        this.$emit('input', {
          ...this.value,
          publisher
        });
      }
    },
    receivers: {
      get() {
        return this.value.receivers;
      },
      set(receivers) {
        this.$emit('input', {
          ...this.value,
          receivers
        });
      }
    },
    subject: {
      get() {
        return this.value.subject;
      },
      set(subject) {
        this.$emit('input', {
          ...this.value,
          subject
        });
      }
    },
    content: {
      get() {
        return this.value.content;
      },
      set(content) {
        this.$emit('input', {
          ...this.value,
          content
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-avatar {
  margin-top: 7px;
}
.from {
  max-width: 20vw;
}
.to {
  max-width: 40vw;
}
.overlay-wrapper {
  position: relative;
  .overlay {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
}

:deep(.tox-tinymce) {
  min-height: 100% !important;
  border: none !important;

  .tox-edit-area {
    margin: 24px;
  }
}

:deep(.tox-toolbar__primary) {
  background-image: none;
  background-color: #f9f9f9;
}
</style>

import Vue from 'vue';
import router from '@/pwa/helper/router';
import VueAuth from '@websanova/vue-auth/dist/v2/vue-auth';
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js';

Vue.router = router;

const bearerAuth = {
  request: function (req, token) {
    this.drivers.http.setHeaders.call(this, req, {
      Authorization: 'Bearer ' + token
    });
  },
  response: function (res) {
    return (res.data || {}).token;
  }
};

Vue.use(VueAuth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router
  },
  drivers: {
    auth: bearerAuth,
    http: driverHttpAxios,
    router: driverRouterVueRouter
  },
  options: {
    rolesVar: 'roles',
    loginData: {
      url: 'login',
      method: 'POST',
      redirect: '/',
      fetchUser: true
    },
    fetchData: {
      url: 'user/current',
      method: 'GET',
      enabled: true
    },
    refreshData: { enabled: false }
  }
});

export default (db) => ({
  list() {
    return db.getAll('places').catch(() => []);
  },
  async save(places) {
    try {
      if (places.length === 0) return;

      const tx = db.transaction('places', 'readwrite');

      places.forEach((place) => tx.store.put(place));

      await tx.done;

      return Promise.resolve();
    } catch (exception) {
      console.error(exception, places);

      return false;
    }
  },
  clear() {
    return db.clear('places');
  }
});

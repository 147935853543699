import { BehaviorSubject } from 'rxjs';

class BranchService {
  constructor(database, http) {
    this.$data = new BehaviorSubject([]);
    this.database = database;
    this.http = http;

    this.database.branch.list().then((branches) => this.$data.next(branches));
  }

  async fetch(ids) {
    try {
      const branches = (
        await Promise.all(
          ids.map(async (id) => {
            const response = await this.http.get(`public-transport-data/branch-group/${id}`);

            return response.data;
          })
        )
      ).flat();

      await this.database.branch.clear();
      await this.database.branch.save(branches);
      this.$data.next(branches);

      return branches;
    } catch (e) {
      console.warn(e);
    }
  }

  async fetchAll() {
    try {
      const response = await this.http.get(`public-transport-data/branch-groups`);

      await this.database.branch.clear();
      await this.database.branch.save(response.data);
      this.$data.next(response.data);

      return response.data;
    } catch (e) {
      console.warn(e);
    }
  }

  async refresh() {
    return this.$data.next(await this.database.branch.list());
  }

  list() {
    return this.$data;
  }
}

const BranchServicePlugin = {
  install(instance, { database, http }) {
    const service = new BranchService(database, http);

    instance.prototype.$branchService = service;
    instance.branchService = service;
  }
};

export const vueBranchService = (Vue, options) => {
  Vue.use(BranchServicePlugin, options);
};

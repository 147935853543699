<template>
  <div>
    <h2
      :class="{
        'error--text': !requiredRule,
        disabled: this.disabled
      }"
      class="subheadline pb-2"
    >
      {{ $t(headline) }}
    </h2>
    <v-input v-model="value" :name="fieldName" :required="required" :rules="requiredRule" :disabled="disabled">
      <v-btn-toggle
        v-model="selected"
        :mandatory="!nullable"
        class="elevation-0 button-select"
        rounded
        :data-cy="fieldName"
      >
        <v-btn v-for="(option, index) in options" :key="index" :disabled="disabled" text>
          <div class="text-truncate">
            {{ option.label }}
          </div>
        </v-btn>
      </v-btn-toggle>
    </v-input>
  </div>
</template>

<script>
export default {
  name: 'ButtonSelect',
  props: {
    value: [Boolean, String, Number],
    headline: {
      type: String,
      required: true
    },
    nullable: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default() {
        return [
          {
            label: this.$i18n.t('common.yes'),
            value: true
          },
          {
            label: this.$i18n.t('common.no'),
            value: false
          }
        ];
      }
    },
    fieldName: {
      type: String,
      default: 'button-select'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      requiredRule: this.required
        ? [(v) => !!v || this.$t('validations.required', { field: this.$t(this.headline) })]
        : []
    };
  },
  computed: {
    selected: {
      get() {
        let option = this.options.findIndex(({ value }) => value === this.value);

        if (option === -1) {
          return null;
        }

        return option;
      },
      set(selected) {
        if (selected === undefined || this.options[selected] === undefined) {
          return this.$emit('input', null);
        }

        this.$emit('input', this.options[selected].value);
      }
    }
  },
  watch: {
    options() {
      this.$forceUpdate();
    }
  }
};
</script>

<style scoped>
:deep(h2.subheadline) {
  font-size: 0.9rem;
  color: #009ee0;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
}

:deep(h2.subheadline.disabled) {
  color: rgba(0, 0, 0, 0.5) !important;
}

.v-btn-toggle > .v-btn.v-size--default {
  height: 36px !important;
}

.v-btn-toggle {
  width: 100%;
}

.button-select {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.button-select.v-btn-toggle .v-btn {
  flex: 1;
  opacity: 1;
  border: 2px solid #15a2e1;
  border-left: 1px solid #15a2e1;
  border-right: 1px solid #15a2e1;
  color: #15a2e1;
}

:deep(.button-select.v-btn-toggle) .v-btn:first-child {
  border-left: 2px solid #15a2e1;
}

:deep(.button-select.v-btn-toggle) .v-btn:last-child {
  border-right: 2px solid #15a2e1;
}

:deep(.button-select.v-btn-toggle) .v-btn.v-btn--active {
  color: var(--v-white-base);
  border: none;
  background: linear-gradient(to right, #009ee0 0%, #1db4f2 100%);
}

:deep(.button-select.v-btn-toggle) .v-btn.v-btn--active:last-child {
  background: linear-gradient(to right, #1db4f2 0%, #009ee0 100%);
}

:deep(.v-btn__content) {
  width: 100%;
}
</style>

<template>
  <dialog-select
    v-if="(list || []).length > 1"
    v-model="selected"
    :label="$t('common.relation').toUpperCase()"
    :name="fieldName"
    :items="list"
    :required="required"
    :disabled="disabled"
    :rules="requiredRule"
    prepend-inner-icon="fal fa-link"
    append-icon="fal fa-chevron-down"
    item-value="relation"
    :data-cy="fieldName"
  ></dialog-select>
</template>

<script>
import { map, pluck, mergeMap, filter } from 'rxjs/operators';

export default {
  name: 'RelationSelect',
  props: {
    value: {
      type: String
    },
    kind: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      default: 'incident-relation-select'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      requiredRule: [
        (v) => (this.required && !!v) || this.$t('validations.required', { field: this.$t('common.relation') })
      ]
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  subscriptions() {
    return {
      list: this.$watchAsObservable('kind', {
        immediate: true
      }).pipe(
        pluck('newValue'),
        mergeMap((kind) => this.$incidentKindService.findByKind(kind)),
        map((kind) => kind || {}),
        map((kind) => kind.relation || []),
        map((relations) =>
          relations.map((relation) => ({
            relation,
            title: this.$i18n.t(`incident.relation.${relation.toLowerCase()}.title`),
            description: this.$i18n.te(`incident.relation.${relation.toLowerCase()}.description`)
              ? this.$i18n.t(`incident.relation.${relation.toLowerCase()}.description`)
              : undefined
          }))
        )
      )
    };
  },
  created() {
    this.$watchAsObservable('kind', {
      immediate: true
    })
      .pipe(
        pluck('newValue'),
        mergeMap((kind) => this.$incidentKindService.findByKind(kind)),
        map((kind) => kind || {}),
        map((kind) => kind.relation || []),
        filter((list) => list.length === 1)
      )
      .subscribe((list) => {
        this.selected = list[0];
      });
  }
};
</script>

<template>
  <dialog-select
    v-model="selected"
    :label="$t('common.kind').toUpperCase()"
    :name="fieldName"
    :items="filteredIncidentKinds"
    :required="required"
    :disabled="disabled"
    :rules="requiredRule"
    prepend-inner-icon="fal fa-map-marker-exclamation"
    append-icon="fal fa-chevron-down"
    item-value="kind"
    :data-cy="fieldName"
  ></dialog-select>
</template>

<script>
import { map, tap } from 'rxjs/operators';

export default {
  name: 'KindSelect',
  props: {
    value: {
      type: String
    },
    incidentLocationType: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      default: 'incident-kind-select'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      incidentKinds: [],
      requiredRule: [
        (v) => (this.required && !!v) || this.$t('validations.required', { field: this.$t('common.kind') })
      ]
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    filteredIncidentKinds() {
      return this.incidentKinds.filter(({ incidentLocation }) => incidentLocation.includes(this.incidentLocationType));
    }
  },
  watch: {
    filteredIncidentKinds() {
      const selectedNewIndex = this.filteredIncidentKinds.findIndex(({ kind }) => kind === this.selected);
      if (selectedNewIndex === -1) {
        this.selected = null;
      }
    }
  },
  subscriptions() {
    return {
      list: this.$incidentKindService.list().pipe(
        map((types) =>
          types
            .filter(({ visible }) => visible)
            .map(({ kind, incidentLocation }) => ({
              kind,
              incidentLocation,
              title: this.$i18n.t(`incident.kind.${kind.toLowerCase()}.title`),
              description: this.$i18n.te(`incident.kind.${kind.toLowerCase()}.description`)
                ? this.$i18n.t(`incident.kind.${kind.toLowerCase()}.description`)
                : undefined
            }))
            .sort((a, b) => a.title.localeCompare(b.title))
        ),
        tap((incidentKinds) => {
          this.incidentKinds = incidentKinds;
        })
      )
    };
  }
};
</script>

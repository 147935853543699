import Vue from 'vue';
import VueI18n from 'vue-i18n';
import sharedTranslationsDe from '@/shared/translations/de';
import layoutTranslationsDe from '@/shared/modules/Layout/translations/de';
import accountTranslationsDe from '@/shared/modules/Account/translations/de';
import newsTranslationsDe from '@/shared/modules/News/translations/de';
import loginTranslationsDe from '@/pwa/modules/Login/translations/de';
import captureTranslationsDe from '@/pwa/modules/Capture/translations/de';
import archiveTranslationsDe from '@/pwa/modules/Archive/translations/de';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages: {
    de: {
      ...sharedTranslationsDe,
      ...layoutTranslationsDe,
      ...accountTranslationsDe,
      ...newsTranslationsDe,
      ...loginTranslationsDe,
      ...captureTranslationsDe,
      ...archiveTranslationsDe
    }
  }
});

<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :value="value"
    @input="$emit('input', $event)"
    max-width="800"
    attach=".v-application"
    scrollable
  >
    <v-card :data-cy="`dialog-${header}`">
      <v-card-title class="px-6 pb-4">
        <v-icon
          :class="{ invisible: $vuetify.breakpoint.smAndUp }"
          class="pointer"
          @click="$emit('input', !value)"
          data-cy="closeDialog"
        >
          fal fa-arrow-left
        </v-icon>
        <v-spacer></v-spacer>
        <transition name="fade">
          <div v-if="showToolbarTitle" class="grey--text subtitle-1 font-weight-regular text-uppercase line-height-1">
            <span class="letter-spacing-3">{{ header }}</span>
          </div>
        </transition>
        <v-spacer></v-spacer>
        <v-icon
          :class="{ invisible: $vuetify.breakpoint.xsOnly }"
          class="pointer"
          @click="$emit('input', !value)"
          data-cy="closeDialog"
        >
          fal fa-times
        </v-icon>
      </v-card-title>
      <v-card-text class="pa-6" @scroll="onScroll">
        <slot></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    header: String
  },
  data() {
    return {
      showToolbarTitle: false
    };
  },
  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') {
        return;
      }

      const top = window.pageYOffset || e.target.scrollTop || 0;

      this.showToolbarTitle = top > 40;
    }
  }
};
</script>

<style scoped>
.invisible {
  visibility: hidden;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
>>> .v-card__title {
  border-bottom: 1px solid var(--v-grey-lighten2);
}
</style>

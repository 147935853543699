<template>
  <span>
    <span class="pointer" @click="value = !value">
      {{ $t('login.privacy') }}
    </span>
    <styled-dialog v-model="value" :header="$t('login.privacy')">
      <h1 class="display-1 text-center text-uppercase pt-6 pb-12">
        <span class="letter-spacing-3">{{ $t('login.privacy') }}</span>
      </h1>

      <h2 class="subtitle-1 font-weight-bold text-uppercase">
        <span class="letter-spacing-2">1. Datenschutz auf einen Blick</span>
      </h2>

      <h3 class="body-1 font-weight-bold text-uppercase pt-6 mb-4">
        <span class="letter-spacing-2">Allgemeine Hinweise</span>
      </h3>
      Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert,
      wenn Sie unsere Webseite besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert
      werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
      aufgeführten Datenschutzerklärung.

      <v-divider class="my-12"></v-divider>
      <h3 class="body-1 font-weight-bold text-uppercase mb-6">
        <span class="letter-spacing-2">Datenerfassung</span>
      </h3>
      <div class="font-weight-bold">Wer ist verantwortlich für die Datenerfassung?</div>
      Die Datenverarbeitung in dieser Datenbank erfolgt durch den Betreiber als Verantwortlichen. Dessen Kontaktdaten
      können Sie dieser Erklärung unter Punkt 2 entnehmen.
      <br /><br />
      <div class="font-weight-bold">Wie und welche Daten erheben wir?</div>
      Ihre Daten werden beim und nach Einloggen in unser Programm durch unsere IT-Systeme erhoben. Das sind die
      <ul class="py-4">
        <li>Log-In Daten des Nutzers, sowie</li>
        <li>Daten zu Zeit, Ort und Sicherheitsvorfall</li>
      </ul>
      sowie automatische Daten, die Ihr Browser überträgt (Server-Log-Dateien):
      <ul class="py-4">
        <li>Browsertyp und Browserversion</li>
        <li>verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
        <li>IP-Adresse</li>
        <li>Der IP-Adresse zugeordnete Namen</li>
        <li>Portnr. des Browsers</li>
        <li>Akzeptierte Dateitypen</li>
      </ul>
      Bei den erhobenen Daten handelt es sich ausschließlich um Daten, die keinen Personenbezug haben.
      <br /><br />
      <div class="font-weight-bold">Wofür nutzen wir die Daten?</div>
      Die gespeicherten anonymen Daten werden statistisch ausgewertet, um ein Sicherheitslagebild erstellen und
      geeignete Präventionsmaßnahmen einleiten zu können. Die Server-Log-Dateien werden ggf. zur administrativen Wartung
      und ggf. Wiederherstellung des Sytembetriebs im Fehlerfall genutzt.
      <br /><br />
      <div class="font-weight-bold">Weitere Informationen</div>
      Bei Fragen zum Thema Datenschutz können Sie sich jederzeit unter der in der Datenschutzerklärung angegebenen
      Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
      <br /><br />
      Die Anschrift der für unser Unternehmen zuständigen Datenschutzaufsichtsbehörde lautet: Landesbeauftragte für
      Datenschutz und Informationsfreiheit Nordrhein-Westfalen (LDI NRW), Kavalleriestr. 2-4, 40213 Düsseldorf.

      <v-divider class="my-12"></v-divider>
      <h2 class="subtitle-1 font-weight-bold text-uppercase">
        <span class="letter-spacing-2">2. Allgemeine Hinweise und Pflichtinformationen</span>
      </h2>

      <h3 class="body-1 font-weight-bold text-uppercase pt-6 mb-4">
        <span class="letter-spacing-2">Hinweis zur verantwortlichen Stelle</span>
      </h3>
      Die verantwortliche Stelle für die Datenverarbeitung auf dieser Webseite ist:
      <br /><br />
      Verkehrsverbund Rhein-Ruhr AöR<br />
      Augustastraße 1<br />
      45879 Gelsenkirchen<br />
      Telefon: +49 209 1584-0<br />
      Telefax: +49 209 23967<br />
      E-Mail: info(at)vrr.de

      <v-divider class="my-12"></v-divider>
      <h3 class="body-1 font-weight-bold text-uppercase mb-4">
        <span class="letter-spacing-2">Datenschutzbeauftragter</span>
      </h3>
      Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt.
      <br /><br />
      Verkehrsverbund Rhein-Ruhr AöR<br />
      Datenschutzbeauftragter<br />
      Augustastraße 1<br />
      45879 Gelsenkirchen <br /><br />
      Telefon: +49 209 1584 0<br />
      E-Mail: datenschutz@vrr.de
    </styled-dialog>
  </span>
</template>

<script>
import StyledDialog from '@/shared/components/StyledDialog';

export default {
  data() {
    return {
      value: false
    };
  },
  components: {
    StyledDialog
  }
};
</script>

<template>
  <v-bottom-navigation :value="true" app fixed grow color="transparent" class="foreground" height="auto">
    <div v-for="entry in menuEntries" :key="entry.index">
      <v-btn
        v-if="hasAccess(entry.routeName)"
        :to="entry.routeName ? { name: entry.routeName } : null"
        :disabled="menuLocked"
        text
      >
        <v-icon color="white">{{ entry.icon }}</v-icon>
      </v-btn>
    </div>
  </v-bottom-navigation>
</template>

<script>
import { mapState } from 'vuex';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/store';

export default {
  data() {
    return {
      menuEntries: [
        {
          routeName: 'capture',
          icon: 'fas fa-file-edit',
          title: this.$t('layout.capture')
        },
        {
          routeName: 'archive',
          icon: 'fas fa-archive',
          title: this.$t('layout.archive')
        }
      ]
    };
  },
  computed: {
    ...mapState(LAYOUT_NAMESPACE, ['menuLocked'])
  },
  methods: {
    hasAccess(routeName) {
      const route = this.$router.options.routes[1].children.find((route) => route.name === routeName);

      if (!route.meta) {
        return false;
      }

      if (Array.isArray(route.meta.auth)) {
        return this.$auth.check(route.meta.auth);
      }

      return route.meta.auth.roles ? this.$auth.check(route.meta.auth.roles) : false;
    }
  }
};
</script>

<style scoped>
.v-bottom-navigation {
  background: linear-gradient(to right, #00cdf2 0%, #009ee0 100%);
}

.v-bottom-navigation .v-btn {
  height: 60px !important;
}

.v-bottom-navigation >>> .v-btn--disabled .v-icon {
  color: var(--v-primary-lighten3) !important;
}

.foreground {
  z-index: 3;
}

.v-bottom-navigation > div,
.v-bottom-navigation .v-btn {
  width: 100% !important;
  max-width: 100% !important;
}
</style>

export const authUser = ($auth, property) => {
  if (null === $auth.user()) {
    return null;
  }

  return $auth.user()[property] || null;
};

export const hasUserRole = ($auth, role) => {
  if (null === $auth.user()) {
    return false;
  }

  return $auth.user().roles.includes(role);
};

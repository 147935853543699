<template>
  <div>
    <desktop-toolbar></desktop-toolbar>
    <desktop-sidebar></desktop-sidebar>
    <content-area :key="$route.name">
      <slot />
    </content-area>
  </div>
</template>

<script>
import DesktopToolbar from '@/shared/modules/Layout/components/DesktopToolbar';
import DesktopSidebar from '@/shared/modules/Layout/components/DesktopSidebar';
import ContentArea from '@/shared/modules/Layout/components/ContentArea';

export default {
  components: {
    DesktopToolbar,
    DesktopSidebar,
    ContentArea
  }
};
</script>

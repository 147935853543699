export default {
  news: {
    maintenanceText: 'Der Newsbereich steht während einer Wartung leider nicht zur Verfügung.',
    offlineText: 'Der Newsbereich steht offline leider nicht zur Verfügung.',
    active: 'aktiv',
    inactive: 'inaktiv',
    from: 'von',
    to: 'an',
    subject: 'Betreff',
    receiver: {
      system_administrator: 'Systemadmin',
      super_administrator: 'Superadmin',
      administrator: 'Admin',
      reporter: 'Erfasser',
      data_user: 'Datennutzer',
      evaluator: 'Auswerter'
    },
    showNews: {
      title: 'News',
      newsList: {
        search: 'Suche nach News...',
        delete: {
          reallyDelete: 'Wirklich löschen?',
          cantDelete: 'Löschen nicht möglich.',
          doYouWantDoDelete: 'Soll diese News wirklich unwiderruflich gelöscht werden?',
          youCanNotDelete: 'Diese News kann aktuell nicht gelöscht werden, da sie aktiv ist.',
          cancel: 'Abbrechen',
          deleteNow: 'Entgültig löschen'
        }
      },
      newsContent: {
        visibleFor: 'Sichtbar für',
        readings: 'Ansichten',
        you: 'Sie',
        at: 'um'
      }
    },
    createNews: {
      title: 'News erstellen',
      success: 'Die News wurde erfolgreich gespeichert, muss aber noch aktiviert werden.'
    },
    editNews: {
      title: 'News bearbeiten',
      success: 'Die News wurde erfolgreich aktualisiert.'
    },
    deleteNews: {
      success: 'Die News wurde erfolgreich gelöscht.'
    },
    newsForm: {
      image: 'Bild einfügen'
    }
  }
};

<template>
  <v-container
    v-if="isUnavailable"
    :class="{
      mobile: $vuetify.breakpoint.xs
    }"
    class="offline-message"
    fill-height
  >
    <v-layout wrap>
      <v-flex xs12 class="align-self-center">
        <v-card-text class="title text-center">
          <span v-if="maintenanceMode">{{ $t('account.maintenanceText') }}</span>
          <span v-else>{{ $t('account.offlineText') }}</span>
          <offline-snackbar />
        </v-card-text>
      </v-flex>
    </v-layout>
  </v-container>

  <styled-box v-else :headline="$t('account.passwordChange')" :showOnMobile="true" data-cy="passwordChangeForm">
    <v-container grid-list-xl>
      <v-layout wrap>
        <v-flex md12 lg11 offset-lg1>
          <div v-if="forcedChange">
            {{ $t('account.passwordChangeRequired') }}
          </div>
          {{ $t('account.passwordMustSecure') }}
        </v-flex>
        <v-flex xs12 md6 lg5 offset-lg1>
          <v-list disabled>
            <v-list-item-group>
              <v-list-item v-for="criteriaItem in criteria" :key="criteriaItem.index">
                <v-list-item-icon>
                  <v-icon v-if="criteriaItem.status" color="success">fal fa-check</v-icon>
                  <v-icon v-else color="error">fal fa-times</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ criteriaItem.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-flex>

        <v-flex xs12 md6 lg5>
          <v-form @submit.prevent="onSubmit">
            <v-text-field
              v-model="models.password"
              :error-messages="errorMessages.password"
              @input="resetValidation"
              type="password"
              :label="$t('account.currentPassword')"
              class="mb-3"
              data-cy="currentPassword"
            ></v-text-field>
            <v-text-field
              v-model="models.newPassword"
              :error-messages="errorMessages.newPassword"
              @input="onInput"
              type="password"
              :label="$t('account.newPassword')"
              class="mb-3"
              data-cy="newPassword"
            ></v-text-field>
            <v-text-field
              v-model="models.newPasswordRepeat"
              :error-messages="errorMessages.newPasswordRepeat"
              @input="resetValidation"
              type="password"
              :label="$t('account.newPasswordRepeat')"
              class="mb-3"
              data-cy="newPasswordRepeat"
            ></v-text-field>
            <v-btn :loading="loading" type="submit" color="primary" class="mt-4" data-cy="submit" outlined rounded>{{
              $t('account.changePasswordNow')
            }}</v-btn>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </styled-box>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/store';
import { ACCOUNT_NAMESPACE, CHANGE_USER_PASSWORD } from '@/shared/modules/Account/store';
import OfflineSnackbar from '@/shared/components/OfflineSnackbar';
import { authUser } from '@/shared/helper/vue-auth';
import UnavailableMixin from '@/pwa/modules/Layout/components/UnavailableMixin.vue';

export default {
  mixins: [UnavailableMixin],
  components: {
    OfflineSnackbar
  },
  data() {
    return {
      errorMessages: {
        password: [],
        newPassword: [],
        newPasswordRepeat: []
      },
      models: {
        password: '',
        newPassword: '',
        newPasswordRepeat: ''
      },
      loading: false,
      criteria: [
        {
          text: this.$t('account.criteria.length'),
          status: false,
          check: (value) => value.length >= 6
        },
        {
          text: this.$t('account.criteria.number'),
          status: false,
          check: (value) => value.search(/\d/) !== -1
        },
        {
          text: this.$t('account.criteria.letter'),
          status: false,
          check: (value) => value.search(/[a-zA-Z]/) !== -1
        },
        {
          text: this.$t('account.criteria.specialCharacter'),
          status: false,
          check: (value) => value.search(/[@$!%*#?&]/) !== -1
        }
      ]
    };
  },
  computed: {
    forcedChange() {
      return this.$route.name.search('account') < 0;
    }
  },
  methods: {
    ...mapMutations(LAYOUT_NAMESPACE, ['lockMenu', 'unlockMenu', 'showGlobalSnackbar']),
    ...mapActions(ACCOUNT_NAMESPACE, {
      changeUserPassword: CHANGE_USER_PASSWORD
    }),
    resetValidation() {
      this.errorMessages = {
        password: [],
        newPassword: [],
        newPasswordRepeat: []
      };
    },
    validate() {
      if (this.models.password === '') {
        this.errorMessages.password.push(this.$t('account.error.required'));
      }

      if (this.models.newPassword === '') {
        this.errorMessages.newPassword.push(this.$t('account.error.required'));
      }

      if (this.models.newPasswordRepeat === '') {
        this.errorMessages.newPasswordRepeat.push(this.$t('account.error.required'));
      }

      if (this.models.newPassword !== this.models.newPasswordRepeat) {
        this.errorMessages.newPasswordRepeat.push(this.$t('account.error.passwordsNotEqual'));
      }

      if (this.criteria.filter((criteria) => !criteria.status).length > 0) {
        this.errorMessages.newPassword.push(this.$t('account.error.passwordDontMatchCriteria'));
      }
    },
    onInput(value) {
      this.resetValidation();
      for (let index = 0; index < this.criteria.length; index++) {
        this.criteria[index].status = this.criteria[index].check(value);
      }
    },
    async onSubmit() {
      this.validate();

      if (
        this.errorMessages.password.length +
          this.errorMessages.newPassword.length +
          this.errorMessages.newPasswordRepeat.length >
        0
      ) {
        return;
      }

      this.loading = true;

      const result = await this.changeUserPassword({
        userId: authUser(this.$auth, 'id'),
        password: this.models.password,
        newPassword: this.models.newPassword
      });

      this.loading = false;

      if (result.success) {
        this.models = {
          password: '',
          newPassword: '',
          newPasswordRepeat: ''
        };

        await this.$auth.fetch({});
        this.showGlobalSnackbar({
          text: 'account.success',
          color: 'success',
          icon: 'fal fa-check'
        });
      }

      if (!result.success && result.code === 401) {
        this.errorMessages.password.push(this.$t('account.error.passwordWrong'));
        return;
      }

      if (!result.success && result.code === 406) {
        this.errorMessages.newPassword.push(this.$t('account.error.newPasswordEqualsCurrentPassword'));
        return;
      }

      if (!result.success && !result.code) {
        this.showGlobalSnackbar({
          text: 'account.error.general',
          color: 'error',
          icon: 'fal fa-times'
        });
      }
    }
  },
  mounted() {
    if (this.forcedChange) {
      this.lockMenu();
    }
  },
  beforeDestroy() {
    this.unlockMenu();
  }
};
</script>

<style scoped>
.offline-message.mobile {
  color: var(--v-white-base);
  background: url('~@/shared/assets/background-menu.png') no-repeat bottom center;
  background-size: cover;
}
</style>

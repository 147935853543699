<template>
  <v-flex class="text-center align-center">
    <div
      class="primary--text text-uppercase mb-4"
      :class="{
        'mt-6': $vuetify.breakpoint.smAndUp,
        'mt-2': $vuetify.breakpoint.xsOnly
      }"
    >
      {{ $t('captureList.filter.title') }}
    </div>
    <div class="button-group-wrapper" :class="{ 'text-left': $vuetify.breakpoint.smAndDown }">
      <div class="text-no-wrap">
        <v-btn
          rounded
          color="primary"
          class="elevation-0 mr-4"
          :outlined="!isActive(filterStates.ALL)"
          @click="activate(filterStates.ALL)"
        >
          {{ $t('captureList.filter.all') }}
        </v-btn>
        <v-btn
          rounded
          color="primary"
          class="elevation-0 mr-4"
          :outlined="!isActive(filterStates.INCOMPLETE)"
          @click="activate(filterStates.INCOMPLETE)"
        >
          {{ $t('captureList.filter.incomplete') }}
        </v-btn>
        <v-btn
          rounded
          color="primary"
          class="elevation-0 mr-4"
          :outlined="!isActive(filterStates.COMPLETE)"
          @click="activate(filterStates.COMPLETE)"
        >
          {{ $t('captureList.filter.complete') }}
        </v-btn>
        <v-btn
          rounded
          color="primary"
          class="elevation-0"
          :outlined="!isActive(filterStates.SYNCED)"
          @click="activate(filterStates.SYNCED)"
        >
          {{ $t('captureList.filter.send') }}
        </v-btn>
      </div>
    </div>
  </v-flex>
</template>

<script>
export default {
  name: 'ListFiler',
  data() {
    return {
      activeFilter: '',
      filterStates: {
        ALL: 'ALL',
        SYNCED: 'SYNCED',
        COMPLETE: 'COMPLETE',
        INCOMPLETE: 'INCOMPLETE'
      }
    };
  },
  props: {},
  computed: {},
  methods: {
    isActive(state) {
      return this.activeFilter === state;
    },
    activate(state) {
      this.activeFilter = state;
      this.$emit('onFilterChange', state);
    }
  },
  mounted() {
    this.activeFilter = this.filterStates.ALL;
  }
};
</script>

<style>
.button-group-wrapper {
  width: 100%;
  overflow-x: scroll;
}
</style>

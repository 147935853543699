export default {
  captureList: {
    title: 'Liste',
    noEntries: 'Liste leer',
    firstCaptureHint: 'Erste Erfassung mit +Icon durchführen',
    filter: {
      title: 'Filter',
      complete: 'vollständig',
      incomplete: 'unvollständig',
      all: 'Alle',
      send: 'Übertragen'
    },
    snackbar: {
      offline: 'Sie sind offline',
      incidentAdded: 'Erfassung erfolgreich hinzugefügt',
      incidentUpdated: 'Erfassung erfolgreich aktualisiert',
      incidentDeleted: 'Datensatz wurde gelöscht'
    }
  },
  capture: {
    duplicate: 'Duplizieren',
    snackbar: {
      incompleteForm: 'Unvollständige Erfassung'
    },
    dialog: {
      incomplete: 'Unvollständige Erfassung',
      deleteTitle: 'Datensatz unwiderruflich löschen?',
      duplicate: 'Wurden wirklich alle Daten angepasst?'
    },
    form: {
      transmittedAt: 'Übertragen am',
      lastUpdateAt: 'Zuletzt aktualisiert am'
    }
  }
};

export const ACCOUNT_NAMESPACE = 'account';
export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';

export default (Vue) => ({
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async [CHANGE_USER_PASSWORD](_, { userId, password, newPassword }) {
      return Vue.prototype.$http
        .post('/commands/user-management/user/update-password', {
          userId: userId,
          password: password,
          newPassword: newPassword
        })
        .then(() => {
          return {
            success: true
          };
        })
        .catch((error) => {
          return {
            success: false,
            code: error.response.data.code || false
          };
        });
    }
  }
});

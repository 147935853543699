export default {
  archiveList: {
    title: 'Übersicht',
    line: 'Linie',
    date: 'Datum',
    time: 'Uhrzeit',
    locality: 'Ort',
    stop: 'Haltepunkt',
    incident: 'Ereignis',
    branch: 'Betreiber',
    offlineTitle: 'Sie sind offline',
    maintenanceText: 'Das Archiv steht während einer Wartung leider nicht zur Verfügung',
    offlineText: 'Das Archiv steht offline leider nicht zur Verfügung',
    snackbar: {
      incidentUpdated: 'Erfassung erfolgreich aktualisiert',
      incidentDeleted: 'Datensatz wurde gelöscht'
    }
  }
};

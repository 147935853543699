<template>
  <v-navigation-drawer app temporary v-model="menu">
    <div class="text-right pointer pa-4" @click="toggleMenu">
      <v-icon> far fa-align-right </v-icon>
    </div>
    <v-flex shrink>
      <v-img
        :src="require('@/shared/assets/logos/kcs.svg')"
        height="80"
        position="center"
        contain
        class="mb-12"
        alt="KCS Logo"
      ></v-img>
    </v-flex>
    <v-list data-cy="navigationList">
      <span v-for="entry in menuEntries" :key="entry.index">
        <v-list-item
          v-if="hasAccess(entry.routeName)"
          :to="entry.routeName ? { name: entry.routeName } : null"
          :disabled="menuLocked"
          :data-cy="'navigationItem-' + entry.title.replace(/\s/, '')"
        >
          <v-list-item-action>
            <v-icon>{{ entry.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ entry.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </span>
    </v-list>

    <v-list class="logout mt-5">
      <v-list-item class="text-uppercase" @click="doLogout" data-cy="logout">
        <v-list-item-action>
          <v-icon color="secondary">fas fa-sign-out-alt</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('layout.logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-flex grow class="background"></v-flex>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/store';

export default {
  data() {
    return {
      menuEntries: [
        {
          routeName: 'capture',
          icon: 'fas fa-file-edit',
          title: this.$t('layout.capture')
        },
        {
          routeName: 'archive',
          icon: 'fas fa-archive',
          title: this.$t('layout.archive')
        }
      ]
    };
  },
  computed: {
    menu: {
      get() {
        return this.showMenu;
      },
      set(value) {
        this.setMenu(value);
      }
    },
    ...mapState(LAYOUT_NAMESPACE, ['showMenu', 'menuLocked'])
  },
  methods: {
    hasAccess(routeName) {
      const route = this.$router.options.routes[1].children.find((route) => route.name === routeName);

      if (!route.meta) {
        return false;
      }

      if (Array.isArray(route.meta.auth)) {
        return this.$auth.check(route.meta.auth);
      }

      return route.meta.auth.roles ? this.$auth.check(route.meta.auth.roles) : false;
    },
    doLogout() {
      this.$auth.logout({
        redirect: {
          name: 'login'
        }
      });
    },
    ...mapMutations(LAYOUT_NAMESPACE, ['toggleMenu', 'setMenu'])
  }
};
</script>

<style scoped>
.v-list {
  padding: 0;
  position: relative;
  z-index: 2;
}
.v-list-item {
  color: var(--v-grey-base);
  height: 64px;
}
.v-list-item--active {
  border-right: 3px solid var(--v-primary-base);
}
.v-list-item--active.v-list-item--disabled {
  border-right: none;
}
.v-list-item--active:before {
  background-color: transparent;
}
.v-list-item__action {
  justify-content: center;
  min-width: 48px;
  margin-right: 15px;
}
.v-list-item__action .v-icon {
  color: var(--v-grey-base);
}
.v-list-item__content {
  font-size: 16px;
}
.v-list-item__content .v-list-item__title {
  color: var(--v-grey-base);
}
.v-list-item--active .v-list-item__title,
.v-list-item--active .v-icon {
  color: var(--v-primary-base);
}
.v-list-item--active.v-list-item--disabled .v-list-item__title,
.v-list-item--active.v-list-item--disabled .v-icon {
  color: var(--v-grey-base);
}
.logout {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.background {
  width: 256px;
  height: calc(100vh - 350px);

  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;

  background: url('~@/shared/assets/background-menu.png') no-repeat top center;
  background-size: cover;
}
</style>

<template>
  <dialog-select
    v-if="(list || []).length > 1"
    v-model="selected"
    :label="$t('common.affectedPeople').toUpperCase()"
    :name="fieldName"
    :items="list"
    :required="required"
    :disabled="disabled"
    :rules="requiredRule"
    prepend-inner-icon="fal fa-street-view"
    append-icon="fal fa-chevron-down"
    item-text="title"
    item-value="people"
    :data-cy="fieldName"
  ></dialog-select>
</template>

<script>
import { map, pluck, mergeMap, tap } from 'rxjs/operators';
import { NO_INFORMATION } from '@/shared/modules/Layout/translations/de';

export default {
  name: 'AffectedPeople',
  props: {
    value: {
      type: String
    },
    kind: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      default: 'incident-affected-people-select'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      requiredRule: [
        (v) => (this.required && !!v) || this.$t('validations.required', { field: this.$t('common.affectedPeople') })
      ]
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  subscriptions() {
    return {
      list: this.$watchAsObservable('kind', {
        immediate: true
      }).pipe(
        pluck('newValue'),
        mergeMap((kind) => this.$incidentKindService.findByKind(kind)),
        map((kind) => kind || {}),
        map((kind) => kind.affectedPeople || []),
        tap((affectedPeople) => {
          if (affectedPeople.length === 1) {
            this.selected = affectedPeople[0];
          }
        }),
        map((affectedPeople) =>
          affectedPeople
            .map((people) => ({
              people,
              title: this.$i18n.t(`incident.affectedPeople.${people.toLowerCase()}.title`),
              description: this.$i18n.te(`incident.affectedPeople.${people.toLowerCase()}.description`)
                ? this.$i18n.t(`incident.affectedPeople.${people.toLowerCase()}.description`)
                : undefined
            }))
            .sort((a, b) => {
              if (b.title === NO_INFORMATION) return 1;

              return a.title.localeCompare(b.title);
            })
        )
      )
    };
  }
};
</script>

import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

const SENTRY_OFFLINE_QUEUE_INTERVAL = 60000;
const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;
const SENTRY_ENVIRONMENT = process.env.VUE_APP_SENTRY_ENVIRONMENT;
const SENTRY_STORAGE_KEY = 'sentryOffline';

let sentryOfflineQueue = false;
let sentryOfflineErrors = [];

const processOfflineErrors = () => {
  if (localStorage.getItem(SENTRY_STORAGE_KEY) !== null && navigator.onLine === true) {
    let sentryEvents = JSON.parse(localStorage.getItem(SENTRY_STORAGE_KEY));
    if (sentryEvents !== null && sentryEvents.length > 0) {
      sentryEvents.forEach(function (event) {
        Sentry.captureEvent(event);
      });
    }

    localStorage.setItem(SENTRY_STORAGE_KEY, null);
    clearInterval(sentryOfflineQueue);
  }
};

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT || 'local',
    beforeSend(event) {
      if (Vue.prototype.$auth.user()) {
        event.user = {
          id: Vue.prototype.$auth.user().id,
          username: Vue.prototype.$auth.user().username
        };
      }

      if (navigator.onLine === false) {
        sentryOfflineErrors.push(event);
        localStorage.setItem(SENTRY_STORAGE_KEY, JSON.stringify(sentryOfflineErrors));
        sentryOfflineQueue = setInterval(processOfflineErrors, SENTRY_OFFLINE_QUEUE_INTERVAL);
        return null;
      }

      return event;
    },
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true
      })
    ]
  });
}

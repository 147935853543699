<template>
  <span
    :class="{
      mobile: $vuetify.breakpoint.xsOnly,
      empty: !hasItems,
      offline: this.isUnavailable
    }"
  >
    <v-btn bottom fab color="secondary" class="add-button" @click="showCaptureForm" data-cy="btnShowCaptureForm">
      <v-icon>fal fa-plus</v-icon>
    </v-btn>

    <styled-box v-if="hasItems" :headline="$router.currentRoute.meta.title" :showOnMobile="true" data-cy="captureList">
      <template slot="subheadline">
        <div class="pa-2" v-if="$vuetify.breakpoint.smAndUp"></div>
      </template>

      <list-filter @onFilterChange="setFilterState" />

      <styled-data-table
        :headers="tableHeader"
        :items="filteredData"
        :items-per-page="-1"
        :hide-default-header="$vuetify.breakpoint.xsOnly"
        item-key="incidentId"
        class="pt-6"
        hide-default-footer
        disable-sort
      >
        <template slot="item" slot-scope="props">
          <tr
            @click="
              $router.push({
                name: 'capture-details',
                params: { id: props.item.incidentId }
              })
            "
            class="pointer"
          >
            <td class="hidden-md-and-up pointer">
              <div class="font-weight-medium">
                <span v-if="lineTitles[props.item.lineId]"> Linie {{ lineTitles[props.item.lineId] }}, </span>
                {{ stopTitles[props.item.stopId] }}
              </div>
              <div class="grey--text">
                {{ props.item.dateTime | formatDate('dd.MM.yyyy HH:mm') }}
              </div>
            </td>
            <td class="hidden-sm-and-down grey--text">
              {{ props.item.dateTime | formatDate('dd.MM.yyyy HH:mm') }}
            </td>
            <td class="hidden-md-and-down">
              {{ props.item.locality }}
            </td>
            <td class="hidden-sm-and-down">
              {{ stopTitles[props.item.stopId] || '' }}
            </td>
            <td class="hidden-sm-and-down">
              {{ lineTitles[props.item.lineId] || '' }}
            </td>
            <td class="hidden-xs-only">
              <span v-if="props.item.kind">
                {{ $t(`incident.kind.${props.item.kind.toLowerCase()}.title`) }}
              </span>
            </td>
            <td class="hidden-lg-and-down">
              {{ branchTitles[props.item.branchGroupId] || '' }}
            </td>
            <td class="text-right">
              <v-icon class="pl-4"> fal fa-{{ getIncidentStateIcon(props.item) }} </v-icon>
            </td>
            <td class="text-right">
              <v-icon color="grey">fal fa-chevron-right</v-icon>
            </td>
          </tr>
        </template>
      </styled-data-table>

      <to-top-button :class="{ offline: this.isUnavailable }" />
    </styled-box>

    <v-layout align-center fill-height class="onboarding" v-if="!hasItems">
      <v-flex class="title text-center px-4">
        {{ $t('captureList.firstCaptureHint') }}
      </v-flex>
    </v-layout>

    <offline-snackbar />

    <styled-snackbar :value="snackbar === 'added'" color="success" top>
      {{ $t('captureList.snackbar.incidentAdded') }}
    </styled-snackbar>

    <styled-snackbar :value="snackbar === 'removed'" color="error" top>
      {{ $t('captureList.snackbar.incidentDeleted') }}
    </styled-snackbar>

    <styled-snackbar :value="snackbar === 'updated'" color="success" top>
      {{ $t('captureList.snackbar.incidentUpdated') }}
    </styled-snackbar>
  </span>
</template>

<script>
import OfflineSnackbar from '@/shared/components/OfflineSnackbar';
import ListFilter from '@/pwa/modules/Capture/components/ListFilter';
import ToTopButton from '@/shared/components/ToTopButton';
import { combineLatest, map } from 'rxjs/operators';
import UnavailableMixin from '@/pwa/modules/Layout/components/UnavailableMixin.vue';

export default {
  components: {
    OfflineSnackbar,
    ListFilter,
    ToTopButton
  },
  data() {
    return {
      error: false,
      filterState: 'ALL',
      snackbar: '',
      incidents: [],
      tableHeader: [
        {
          text: `${this.$t('archiveList.line')}/${this.$t('archiveList.stop')}`,
          class: 'hidden-md-and-up',
          sortable: false
        },
        {
          text: `${this.$t('archiveList.date')}/${this.$t('archiveList.time')}`,
          value: 'date_time',
          class: 'hidden-sm-and-down'
        },
        {
          text: this.$t('archiveList.locality'),
          value: 'locality',
          class: 'hidden-md-and-down',
          sortable: false
        },
        {
          text: this.$t('archiveList.stop'),
          value: 'stop_id',
          class: 'hidden-sm-and-down',
          sortable: false
        },
        {
          text: this.$t('archiveList.line'),
          value: 'line_id',
          class: 'hidden-sm-and-down',
          sortable: false
        },
        {
          text: this.$t('archiveList.incident'),
          value: 'kind',
          class: 'hidden-xs-only',
          sortable: false
        },
        {
          text: this.$t('archiveList.branch'),
          value: 'branch_id',
          class: 'hidden-lg-and-down',
          sortable: false
        },
        {
          text: '',
          sortable: false
        },
        {
          text: '',
          sortable: false
        }
      ]
    };
  },
  mixins: [UnavailableMixin],
  computed: {
    hasItems: function () {
      return this.incidents.length > 0;
    },
    filteredData() {
      switch (this.filterState) {
        case 'ALL':
          return this.incidents.filter((incident) => incident.deleted !== 'true');
        case 'SYNCED':
          return this.incidents.filter((incident) => incident.persistence === 'remote' && incident.deleted !== 'true');
        case 'COMPLETE':
          return this.incidents.filter(
            (incident) =>
              incident.incidentType === 'COMPLETE_LINE_REFERENCED' ||
              (incident.incidentType === 'COMPLETE' && incident.deleted !== 'true')
          );
        case 'INCOMPLETE':
          return this.incidents.filter(
            (incident) =>
              incident.deleted !== 'true' && (incident.incidentType === 'INCOMPLETE' || !incident.incidentType)
          );
      }
      return this.incidents;
    }
  },
  methods: {
    setFilterState(state) {
      this.filterState = state;
    },
    getIncidentStateIcon(incident) {
      let stateIconClass = '';

      if (incident.persistence === 'delete') {
        return 'file-times';
      }

      if (incident.incidentType === 'INCOMPLETE' || !incident.incidentType) {
        stateIconClass = 'file-minus';
      } else {
        stateIconClass = 'file-check';
      }

      if (incident.persistence === 'remote') {
        stateIconClass += ' synced';
      }

      return stateIconClass;
    },
    showCaptureForm() {
      this.$currentLocationService.startWatching();
      this.$router.push({ name: 'capture-form' });
    }
  },
  subscriptions() {
    return {
      incidents: this.$incidentService.list().pipe(
        combineLatest(this.$lineService.list()),
        map(([incidents, lines]) =>
          incidents.map((incident) => ({
            ...incident,
            line: lines.find((line) => incident.lineId === line.id)
          }))
        )
      ),
      lineTitles: this.$lineService.list().pipe(
        map((lines) =>
          lines.reduce((obj, line) => {
            obj[line.id] = line.title;

            return obj;
          }, {})
        )
      ),
      stopTitles: this.$stopService.list().pipe(
        map((lines) =>
          lines.reduce((obj, stop) => {
            obj[stop.id] = stop.title;

            return obj;
          }, {})
        )
      ),
      branchTitles: this.$branchService.list().pipe(
        map((lines) =>
          lines.reduce((obj, branch) => {
            obj[branch.id] = branch.title;

            return obj;
          }, {})
        )
      )
    };
  },
  mounted() {
    this.snackbar = this.$route.meta.snackbar;
    this.$route.meta.snackbar = '';
  }
};
</script>

<style scoped>
.add-button {
  top: 4.75rem;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  z-index: 4;
}
.mobile .add-button {
  top: auto;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%) translateY(-55%);
  position: fixed;
  z-index: 4;
}
.empty .add-button {
  top: auto;
  left: 50%;
  bottom: 50%;
  transform: translateX(-50%) translateY(-10%);
  position: absolute;
  z-index: 4;
}
.onboarding {
  color: var(--v-black-base);
}
.mobile .onboarding {
  color: var(--v-white-base);
  background: url('~@/shared/assets/background-menu.png') no-repeat bottom center;
  background-size: cover;
}
.onboarding .flex {
  margin-top: 3em;
  height: 3rem;
}
.mobile .offline-snackbar >>> .v-snack__content {
  padding-top: 15px;
  padding-bottom: 35px;
}
.mobile.empty .offline-snackbar >>> .v-snack__content {
  padding-bottom: 15px;
}
.mobile >>> .to-top-button {
  bottom: 4.75rem;
}
.mobile >>> .to-top-button.offline {
  bottom: 9.5rem;
}
.offline >>> .v-data-table {
  margin-bottom: 4.5rem;
}
.v-icon.fa-file-minus,
.v-icon.fa-file-check {
  color: var(--v-grey-base);
}
.v-icon.synced {
  color: var(--v-primary-base);
}
.v-icon.fa-file-minus.synced {
  color: var(--v-error-base);
}
</style>

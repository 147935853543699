export default {
  light: {
    primary: '#009EE0',
    secondary: '#F29400',
    accent: '#E75112',
    error: '#EA5858',
    info: '#009EE0',
    success: '#4CAF50',
    warning: '#F29400',
    grey: '#ACACAC',
    white: '#FFFFFF',
    black: '#000000'
  }
};

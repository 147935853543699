export default (db) => ({
  list() {
    return db.getAll('incidents').catch(() => []);
  },
  get(incidentId) {
    return db.get('incidents', incidentId).catch((error) => {
      console.error(error);
    });
  },
  findLocalPersisted() {
    return db.getAllFromIndex('incidents', 'persistence', 'local').catch(() => []);
  },
  findDeleted() {
    return db.getAllFromIndex('incidents', 'persistence', 'delete').catch(() => []);
  },
  findRemotePersisted() {
    return db.getAllFromIndex('incidents', 'persistence', 'remote').catch(() => []);
  },
  async saveAll(incidents) {
    const deleted = (await this.findDeleted()).map((item) => item.incidentId);

    const tx = db.transaction('incidents', 'readwrite');

    incidents.forEach((incident) => {
      if (deleted.includes(incident.incidentId)) {
        return;
      }

      return tx.store.put(incident);
    });

    await tx.done;

    return true;
  },
  save(incident) {
    return db.put('incidents', incident);
  },
  remove(incidentId) {
    return db.delete('incidents', incidentId);
  },
  clear() {
    return db.clear('incidents');
  },
  async removeRemoteEntries() {
    const incidents = await this.findRemotePersisted();

    return Promise.all(incidents.map((incident) => db.delete('incidents', incident.incidentId)));
  }
});

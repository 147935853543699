<template>
  <authorized :loading="loading" />
</template>

<script>
import UnavailableMixin from '@/pwa/modules/Layout/components/UnavailableMixin.vue';
import Authorized from '@/shared/modules/Layout/views/Authorized';
import Worker from '@/pwa/helper/import.worker';

export default {
  mixins: [UnavailableMixin],
  components: {
    Authorized
  },
  data: () => ({
    loading: false,
    timeout: null
  }),
  async created() {
    this.$incidentKindService.fetchAll();
    this.$incidentService.fetchAll();

    this.$incidentService.startPeriodicBackendSave();

    if (false === (await this.$importService.shouldImport(this.$auth.user()))) {
      return;
    }

    this.loading = true;

    let branches = this.$auth.user() ? this.$auth.user().branchGroups : [];

    const loadAllBranches = this.$auth.check(['ROLE_SUPER_ADMINISTRATOR', 'ROLE_SYSTEM_ADMINISTRATOR']);

    if (loadAllBranches) {
      branches = (await this.$branchService.fetchAll()).map((branch) => branch.id);
    }

    this.$database.clear();

    const worker = new Worker();
    let completed = 0;

    worker.addEventListener(
      'message',
      () => {
        this.$branchService.refresh();
        this.$lineService.refresh();
        this.$placeService.refresh();
        this.$stopService.refresh();

        completed++;

        if (completed === branches.length) {
          this.$importService.updateLastImport();
          this.loading = false;
        }
      },
      false
    );

    branches.map((branchGroup) => worker.postMessage({ branchGroup, token: this.$auth.token() }));
  },
  mounted() {
    this.checkMaintenanceModePeriodically();
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  }
};
</script>

<template>
  <v-btn
    v-scroll="onScroll"
    v-show="scrollToTop"
    class="to-top-button"
    fab
    dark
    fixed
    bottom
    right
    color="primary"
    @click="toTop"
  >
    <v-icon>fal fa-chevron-up</v-icon>
  </v-btn>
</template>

<script>
export default {
  data() {
    return {
      scrollToTop: false
    };
  },
  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') {
        return;
      }
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.scrollToTop = top > 100;
    },
    toTop() {
      this.$vuetify.goTo(0);
    }
  }
};
</script>

<template>
  <v-btn :to="{ name: 'news' }" data-cy="newsButton" plain icon>
    <v-badge :value="unreadCount" :content="unreadCount" data-cy="newsCount" overlap bottom>
      <v-icon> fal fa-comment-alt-lines </v-icon>
    </v-badge>
  </v-btn>
</template>

<script>
import { mapState } from 'vuex';
import { NEWS_NAMESPACE } from '@/shared/modules/News/store';

export default {
  computed: {
    ...mapState(NEWS_NAMESPACE, ['unreadCount'])
  }
};
</script>

import { of } from 'rxjs';

export default (db) => ({
  list() {
    return db.getAll('incidentKindes').catch(() => []);
  },
  get(kind) {
    if (!kind) {
      return of(undefined);
    }

    return db.get('incidentKindes', kind).catch((error) => {
      console.error(error);
    });
  },
  async save(incidents) {
    return Promise.all(incidents.map((incident) => db.put('incidentKindes', incident)));
  },
  clear() {
    return db.clear('incidentKindes');
  }
});

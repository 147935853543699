import Vue from 'vue';
import Editor from '@tinymce/tinymce-vue';

import 'tinymce/tinymce';

// Theme
import 'tinymce/themes/silver/theme';

// Skins
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';

// Icons
import 'tinymce/icons/default';

// Plugins
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/hr';

// Languages
import 'tinymce-i18n/langs/de';

Vue.component('Editor', Editor);

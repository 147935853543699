import { openDB } from 'idb';
import branch from './branch';
import line from './line';
import place from './place';
import incident from './incident';
import capture from './capture';
import incidentKind from './incidentKind';
import stop from './stop';

export const initDB = async () => {
  const db = await openDB('sidaba-pwa', 1, {
    upgrade(db) {
      db.createObjectStore('branches', {
        keyPath: 'id'
      });

      const lineStore = db.createObjectStore('lines', { keyPath: 'id' });

      lineStore.createIndex('branch', 'branchGroupId', {
        unique: false
      });

      const stopStore = db.createObjectStore('stopes', { keyPath: 'id' });

      stopStore.createIndex('line', 'lineId', {
        unique: false
      });

      const incidentStore = db.createObjectStore('incidents', {
        keyPath: 'incidentId'
      });

      incidentStore.createIndex('persistence', 'persistence', {
        unique: false
      });

      incidentStore.createIndex('deleted', 'deleted', {
        unique: false
      });

      db.createObjectStore('places', { keyPath: 'title' });
      db.createObjectStore('incidentKindes', { keyPath: 'kind' });
      db.createObjectStore('captures', { keyPath: 'id' });
    }
  });

  const branchDB = branch(db);
  const lineDB = line(db);
  const stopDB = stop(db);
  const placeDB = place(db);
  const incidentDB = incident(db);
  const captureDB = capture(db);
  const incidentKindDB = incidentKind(db);

  return {
    branch: branchDB,
    line: lineDB,
    stop: stopDB,
    place: placeDB,
    incident: incidentDB,
    capture: captureDB,
    incidentKind: incidentKindDB,
    clear: () => {
      branchDB.clear();
      lineDB.clear();
      placeDB.clear();
      stopDB.clear();
    }
  };
};

const IndexedDBPlugin = {
  install(instance, options) {
    instance.prototype.$database = options.database;
    instance.database = options.database;
  }
};

export const vueDB = (Vue, database) => {
  Vue.use(IndexedDBPlugin, { database });
};

<template>
  <div class="mobile-landscape-lock white">
    <v-container fluid fill-height>
      <v-layout align-center>
        <v-flex class="text-center">
          <div class="title white--text">
            <div>{{ $t('pleaseRotateDevice') }}</div>
            <div>{{ $t('notAvailableOnMobileLandscape') }}</div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(function () {
      document
        .querySelector('meta[name=viewport]')
        .setAttribute(
          'content',
          `height=${window.innerHeight}, width=${window.innerWidth}, initial-scale=1.0, maximum-scale=1.0, user-scalable=no`
        );
    }, 300);
  }
};
</script>

<style scoped>
.mobile-landscape-lock {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: url('~@/shared/assets/background.png') no-repeat fixed bottom center;
  background-size: 210% auto;
}
@media only screen and (max-width: 1023px) and (orientation: landscape) {
  .mobile-landscape-lock {
    display: block;
  }
}
</style>

import { BehaviorSubject, from } from 'rxjs';

class IncidentKindService {
  constructor(database, http) {
    this.$data = new BehaviorSubject([]);
    this.database = database;
    this.http = http;

    this.database.incidentKind.list().then((types) => this.$data.next(types));
  }

  async fetchAll() {
    try {
      const response = await this.http.get(`incident-capture/base-data/list`);

      await this.database.incidentKind.clear();
      await this.database.incidentKind.save(response.data);
      this.$data.next(response.data);
    } catch (e) {
      console.warn(e);
    }
  }

  list() {
    return this.$data;
  }

  findByKind(kind) {
    return from(this.database.incidentKind.get(kind));
  }
}

const IncidentKindServicePlugin = {
  install(instance, { database, http }) {
    const service = new IncidentKindService(database, http);

    instance.prototype.$incidentKindService = service;
    instance.incidentKindService = service;
  }
};

export const vueIncidentKindService = (Vue, options) => {
  Vue.use(IncidentKindServicePlugin, options);
};

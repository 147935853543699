<script>
import { VueOfflineMixin } from 'vue-offline';
import { mapActions, mapState } from 'vuex';
import { CHECK_MAINTENANCE_MODE, LAYOUT_NAMESPACE } from '@/shared/modules/Layout/store';

export default {
  mixins: [VueOfflineMixin],
  data() {
    return {
      timeout: null
    };
  },
  computed: {
    ...mapState(LAYOUT_NAMESPACE, ['maintenanceMode']),
    isUnavailable() {
      return this.maintenanceMode || this.isOffline;
    }
  },
  methods: {
    ...mapActions(LAYOUT_NAMESPACE, { checkMaintenanceMode: CHECK_MAINTENANCE_MODE }),
    checkMaintenanceModePeriodically() {
      this.checkMaintenanceMode();
      this.timeout = setTimeout(() => {
        this.checkMaintenanceModePeriodically();
      }, 30000);
    }
  }
};
</script>

<template>
  <div
    v-observe-visibility="onVisibilityChange"
    @input="$emit('input', $event)"
    class="v-picker v-card v-picker--time theme--light"
  >
    <div class="v-picker__title picker-header">
      <div class="v-time-picker-title">
        <div class="v-time-picker-title__time">
          <div class="display-3 font-weight-medium">{{ value }}</div>
        </div>
      </div>
    </div>
    <div class="v-picker__body theme--light" style="width: 290px">
      <v-row no-gutters>
        <v-col cols="6">
          <div>
            <ul class="picker-hour v-date-picker-years">
              <li
                v-for="count in 24"
                :key="count"
                :class="{
                  'active primary--text active-hour': String(count - 1).padStart(2, '0') === hour,
                  disabled: isHourDisabled(count - 1)
                }"
                @click="onClickHour(count - 1)"
                class="picker-entry"
              >
                {{ String(count - 1).padStart(2, '0') }}
              </li>
            </ul>
          </div>
        </v-col>
        <v-col cols="6">
          <div>
            <ul class="picker-minute v-date-picker-years">
              <li
                v-for="count in 60"
                :key="count"
                :class="{
                  'active primary--text active-minute': String(count - 1).padStart(2, '0') === minute,
                  disabled: isMinuteDisabled(count - 1)
                }"
                @click="onClickMinute(count - 1)"
                class="picker-entry"
              >
                {{ String(count - 1).padStart(2, '0') }}
              </li>
            </ul>
          </div>
        </v-col>
        <v-col cols="12" class="text-right pt-6 pb-4 px-6">
          <v-btn @click="$emit('ok')" color="primary" class="px-9" depressed rounded>
            {{ $t('common.ok') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { format, setHours, setMinutes, isFuture, parseISO } from 'date-fns';

export default {
  props: {
    value: {
      type: String,
      required: true
    },
    futureDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hour() {
      return format(parseISO('1990-01-01 ' + this.value), 'HH');
    },
    minute() {
      return format(parseISO('1990-01-01 ' + this.value), 'mm');
    }
  },
  methods: {
    onVisibilityChange() {
      setTimeout(() => {
        this.$scrollTo('.active-hour', {
          container: '.picker-hour',
          duration: 1,
          offset: -120,
          easing: 'linear'
        });
      }, 50);
      setTimeout(() => {
        this.$scrollTo('.active-minute', {
          container: '.picker-minute',
          duration: 1,
          offset: -120,
          easing: 'linear'
        });
      }, 100);
    },
    isHourDisabled(hour) {
      if (!this.futureDisabled) {
        return false;
      }

      return isFuture(setHours(new Date(), hour));
    },
    isMinuteDisabled(minutes) {
      if (!this.futureDisabled) {
        return false;
      }

      return isFuture(setHours(setMinutes(new Date(), minutes), this.hour));
    },
    onClickHour(hour) {
      if (this.isHourDisabled(hour)) {
        return;
      }

      this.$emit('input', format(setHours(parseISO('1990-01-01 ' + this.value), hour), 'HH:mm'));
    },
    onClickMinute(minute) {
      if (this.isMinuteDisabled(minute)) {
        return;
      }

      this.$emit('input', format(setMinutes(parseISO('1990-01-01 ' + this.value), minute), 'HH:mm'));
    }
  }
};
</script>

<style scoped>
.picker-entry.disabled {
  color: var(--v-grey-base);
}
.picker-entry.disabled:hover {
  background: none;
  cursor: initial;
}
</style>

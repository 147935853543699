import { BehaviorSubject, of, from } from 'rxjs';
import { map } from 'rxjs/operators';

class LineService {
  constructor(database, http) {
    this.$list = new BehaviorSubject([]);
    this.$byBranch = [];

    this.database = database;
    this.http = http;

    this.database.line.list().then((lines) => {
      this.$list.next(lines);
    });
  }

  async fetchAll(branchIds) {
    try {
      const results = await Promise.all(
        branchIds.map(async (id) => {
          const response = await this.http.get(`public-transport-data/branch-group/${id}/lines`);

          return response.data;
        })
      );

      const lines = results.flat();

      await this.database.line.clear();
      await this.database.line.save(lines);
      this.$list.next(lines);
    } catch (e) {
      console.warn(e);
    }
  }

  async refresh() {
    return this.$list.next(await this.database.line.list());
  }

  list() {
    return this.$list;
  }

  findBy(branch) {
    if (!branch) {
      return of([]);
    }

    return this.$list.pipe(map((lines) => lines.filter((line) => line.branchGroupId === branch)));
  }

  findById(line) {
    if (!line) {
      return of([]);
    }

    return from(this.database.line.get(line));
  }
}

const LineServicePlugin = {
  install(instance, { database, http }) {
    const service = new LineService(database, http);

    instance.prototype.$lineService = service;
    instance.lineService = service;
  }
};

export const vueLineService = (Vue, options) => {
  Vue.use(LineServicePlugin, options);
};

import Vue from 'vue';
import Router from 'vue-router';
import Authorized from '@/pwa/modules/Layout/views/Authorized.vue';
import Login from '@/pwa/modules/Login/views/Login.vue';
import Capture from '@/pwa/modules/Capture/views/Capture.vue';
import CaptureList from '@/pwa/modules/Capture/views/CaptureList.vue';
import ArchiveList from '@/pwa/modules/Archive/views/ArchiveList.vue';
import * as Sentry from '@sentry/browser';
import PasswordChange from '@/shared/modules/Account/views/PasswordChange';
import ShowNews from '@/shared/modules/News/views/ShowNews.vue';
import CreateNews from '@/shared/modules/News/views/CreateNews';
import EditNews from '@/shared/modules/News/views/EditNews';

Vue.use(Router);

const pageTitleSuffix = ' - Sicherheitsdatenbank NRW';

const redirectToFrontendRoute = (path) => {
  let url = process.env.VUE_APP_FRONTEND_URL + '/#' + path;

  if (process.env.NODE_ENV === 'development') {
    url += '?jwt=' + Vue.prototype.$auth.token();
  }

  window.location.href = url;
};

let router = new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        auth: false,
        title: 'Login'
      }
    },
    {
      path: '/',
      component: Authorized,
      meta: {
        auth: true
      },
      children: [
        {
          path: '/',
          redirect: { name: 'capture' }
        },
        {
          path: '/account',
          name: 'account',
          redirect: { name: 'account-password-change' }
        },
        {
          path: '/account/password-change',
          name: 'account-password-change',
          component: PasswordChange,
          meta: {
            auth: true
          }
        },
        {
          path: '/news',
          name: 'news',
          component: ShowNews,
          meta: {
            auth: true,
            title: 'News'
          }
        },
        {
          path: '/news/create',
          name: 'news-create',
          component: CreateNews,
          meta: {
            auth: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_SYSTEM_ADMINISTRATOR'],
            title: 'News erstellen'
          }
        },
        {
          path: '/news/edit/:newsId',
          name: 'news-edit',
          component: EditNews,
          meta: {
            auth: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_SYSTEM_ADMINISTRATOR'],
            title: 'News bearbeiten'
          }
        },
        {
          path: '/capture',
          name: 'capture',
          component: CaptureList,
          meta: {
            auth: {
              roles: [
                'ROLE_SUPER_ADMINISTRATOR',
                'ROLE_SYSTEM_ADMINISTRATOR',
                'ROLE_ADMINISTRATOR',
                'ROLE_EVALUATOR',
                'ROLE_REPORTER',
                'ROLE_API_USER',
                'ROLE_EDK_USER',
                'ROLE_CSP_USER',
                'ROLE_SECURITY_RADAR_USER'
              ],
              forbiddenRedirect: { name: 'reporting' }
            },
            title: 'Erfassung',
            snackbar: ''
          }
        },
        {
          path: '/capture/form',
          name: 'capture-form',
          component: Capture,
          meta: {
            auth: [
              'ROLE_SUPER_ADMINISTRATOR',
              'ROLE_SYSTEM_ADMINISTRATOR',
              'ROLE_ADMINISTRATOR',
              'ROLE_EVALUATOR',
              'ROLE_REPORTER',
              'ROLE_API_USER',
              'ROLE_EDK_USER',
              'ROLE_CSP_USER',
              'ROLE_SECURITY_RADAR_USER'
            ],
            title: 'Erfassung'
          }
        },
        {
          path: '/capture/details/:id',
          name: 'capture-details',
          component: Capture,
          meta: {
            auth: [
              'ROLE_SUPER_ADMINISTRATOR',
              'ROLE_SYSTEM_ADMINISTRATOR',
              'ROLE_ADMINISTRATOR',
              'ROLE_EVALUATOR',
              'ROLE_REPORTER',
              'ROLE_API_USER',
              'ROLE_EDK_USER',
              'ROLE_CSP_USER',
              'ROLE_SECURITY_RADAR_USER'
            ],
            title: 'Erfassung'
          }
        },
        {
          path: '/archive',
          name: 'archive',
          component: ArchiveList,
          meta: {
            auth: [
              'ROLE_SUPER_ADMINISTRATOR',
              'ROLE_SYSTEM_ADMINISTRATOR',
              'ROLE_ADMINISTRATOR',
              'ROLE_EVALUATOR',
              'ROLE_REPORTER',
              'ROLE_API_USER',
              'ROLE_EDK_USER',
              'ROLE_CSP_USER',
              'ROLE_SECURITY_RADAR_USER'
            ],
            title: 'Archiv',
            showGlobalSearch: true
          }
        },
        {
          path: '/archive/details/:id',
          name: 'archive-details',
          component: Capture,
          meta: {
            auth: [
              'ROLE_SUPER_ADMINISTRATOR',
              'ROLE_SYSTEM_ADMINISTRATOR',
              'ROLE_ADMINISTRATOR',
              'ROLE_EVALUATOR',
              'ROLE_REPORTER',
              'ROLE_API_USER',
              'ROLE_EDK_USER',
              'ROLE_CSP_USER',
              'ROLE_SECURITY_RADAR_USER'
            ],
            title: 'Archiv'
          }
        },
        {
          path: '/user-management',
          name: 'user-management',
          beforeEnter() {
            redirectToFrontendRoute('/user-management');
          },
          meta: {
            auth: ['ROLE_ADMINISTRATOR', 'ROLE_SUPER_ADMINISTRATOR', 'ROLE_SYSTEM_ADMINISTRATOR']
          }
        },
        {
          path: '/base-data',
          name: 'base-data',
          beforeEnter() {
            redirectToFrontendRoute('/base-data');
          },
          meta: {
            auth: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_SYSTEM_ADMINISTRATOR']
          }
        },
        {
          path: '/import',
          name: 'import',
          beforeEnter() {
            redirectToFrontendRoute('/import/list');
          },
          meta: {
            auth: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_SYSTEM_ADMINISTRATOR']
          }
        },
        {
          path: '/reporting',
          name: 'reporting',
          beforeEnter() {
            redirectToFrontendRoute('/reporting');
          },
          meta: {
            auth: [
              'ROLE_SUPER_ADMINISTRATOR',
              'ROLE_SYSTEM_ADMINISTRATOR',
              'ROLE_ADMINISTRATOR',
              'ROLE_DATA_USER',
              'ROLE_EVALUATOR'
            ]
          }
        },
        {
          path: '/analysis',
          name: 'analysis',
          beforeEnter() {
            redirectToFrontendRoute('/analysis');
          },
          meta: {
            auth: [
              'ROLE_SUPER_ADMINISTRATOR',
              'ROLE_SYSTEM_ADMINISTRATOR',
              'ROLE_ADMINISTRATOR',
              'ROLE_DATA_USER',
              'ROLE_EVALUATOR'
            ],
            title: 'Analyse'
          }
        },
        {
          path: '/analysis/stops',
          name: 'analysis-stops',
          beforeEnter() {
            redirectToFrontendRoute('/analysis/stops');
          },
          meta: {
            auth: [
              'ROLE_SUPER_ADMINISTRATOR',
              'ROLE_SYSTEM_ADMINISTRATOR',
              'ROLE_ADMINISTRATOR',
              'ROLE_DATA_USER',
              'ROLE_EVALUATOR'
            ],
            title: 'Analyse'
          }
        },
        {
          path: '/analysis/lines',
          name: 'analysis-lines',
          beforeEnter() {
            redirectToFrontendRoute('/analysis/lines');
          },
          meta: {
            auth: [
              'ROLE_SUPER_ADMINISTRATOR',
              'ROLE_SYSTEM_ADMINISTRATOR',
              'ROLE_ADMINISTRATOR',
              'ROLE_DATA_USER',
              'ROLE_EVALUATOR'
            ],
            title: 'Analyse'
          }
        },
        {
          path: '/analysis/stress-prevention',
          name: 'analysis-stress-prevention',
          beforeEnter() {
            redirectToFrontendRoute('/analysis/stress-prevention');
          },
          meta: {
            auth: [
              'ROLE_SUPER_ADMINISTRATOR',
              'ROLE_SYSTEM_ADMINISTRATOR',
              'ROLE_ADMINISTRATOR',
              'ROLE_DATA_USER',
              'ROLE_EVALUATOR'
            ],
            title: 'Analyse'
          }
        }
      ]
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((error) => {
    if (location !== '/login?') {
      Sentry.captureException(error);
    }
  });
};

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title + pageTitleSuffix;
  }

  next();
});

export default router;

import { BehaviorSubject, from } from 'rxjs';

class IncidentService {
  constructor(database, http) {
    this.$data = new BehaviorSubject([]);
    this.database = database;
    this.http = http;

    this.database.incident.list().then((list) => this.$data.next(list));
  }

  async fetchAll() {
    try {
      const response = await this.http.get(`incident-capture/incident/list`, {
        params: { filter: 'today' }
      });

      const incidents = response.data.incidents.map((incident) => ({
        incidentId: incident.id,
        incidentType: incident.incident_type,
        lineId: incident.line_id,
        stopId: incident.stop_id,
        branchGroupId: incident.branch_group_id,
        destinationStopId: incident.destination_stop_id,
        policeInAttendance: incident.police_in_attendance,
        affectedPeople: incident.affected_people,
        dateTime: incident.date_time,
        incidentLocationType: incident.destination_stop_id === null ? 'STOP' : 'LINE',
        type: incident.type,
        kind: incident.kind,
        relation: incident.relation,
        perpetrator: incident.perpetrator,
        showDirections: false,
        locality: incident.locality,
        created: incident.created,
        updated: incident.updated,
        persistence: 'remote'
      }));

      await this.database.incident.removeRemoteEntries();
      await this.database.incident.saveAll(incidents);
      const list = await this.database.incident.list();
      this.$data.next(list);
    } catch (e) {
      console.warn(e);
    }
  }

  async refresh() {
    return this.$data.next(await this.database.incident.list());
  }

  async save(incident) {
    try {
      await this.database.incident.save(incident);
      const list = await this.database.incident.list();

      this.$data.next(list);

      return true;
    } catch (e) {
      console.warn(e);

      return false;
    }
  }

  async remove(incident) {
    try {
      incident.persistence = 'delete';
      await this.database.incident.save(incident);
      const list = await this.database.incident.list();
      this.$data.next(list);
      return true;
    } catch (e) {
      console.warn(e);

      return false;
    }
  }

  list() {
    return this.$data;
  }

  async sendToBackend(incident) {
    let method = 'add';

    if (incident.update === true) {
      method = 'update';
    }

    try {
      const newIncident = {
        ...incident,
        hasLineReference:
          incident.incidentLocationType === 'LINE' ? true : incident.incidentLocationType === 'STOP' ? false : null
      };
      await this.http.post(`commands/incident-capture/incident/${method}`, newIncident, { responseType: 'json' });

      await this.save({
        ...incident,
        persistence: 'remote'
      });
    } catch (error) {
      if (error.response.data.message === 'Incident not found') {
        await this.remove(incident);
      }
    }
  }

  async removeFromBackend(incident) {
    try {
      await this.http.post(
        `commands/incident-capture/incident/remove`,
        { incidentId: incident.incidentId },
        { responseType: 'json' }
      );

      this.database.incident.remove(incident.incidentId);
    } catch (error) {
      if (error.response.data.message === 'Incident not found') {
        this.database.incident.remove(incident.incidentId);
      }
    }
  }

  async startPeriodicBackendSave() {
    if (this.interval) {
      return;
    }

    this.interval = setInterval(async () => {
      const removedIncidents = await this.database.incident.findDeleted();

      removedIncidents.forEach(async (removeIncident) => {
        try {
          await this.removeFromBackend(removeIncident);

          this.refresh();
        } catch (e) {
          console.warn(e);
        }
      });

      const incidents = await this.database.incident.findLocalPersisted();

      incidents.forEach(async (incident) => {
        try {
          await this.sendToBackend(incident);

          this.refresh();
        } catch (e) {
          console.warn(e);
        }
      });
    }, 10000);
  }

  findById(id) {
    return from(this.database.incident.get(id));
  }

  async fetchById(id) {
    try {
      const response = await this.http.get(`incident-capture/incident/details/${id}`).catch((e) => console.error(e));

      const incident = {
        incidentId: response.data.id,
        incidentType: response.data.incident_type,
        lineId: response.data.line_id,
        stopId: response.data.stop_id,
        branchGroupId: response.data.branch_group_id,
        destinationStopId: response.data.destination_stop_id,
        policeInAttendance: response.data.police_in_attendance,
        affectedPeople: response.data.affected_people,
        dateTime: response.data.date_time,
        incidentLocationType: response.data.destination_stop_id === null ? 'STOP' : 'LINE',
        type: response.data.type,
        kind: response.data.kind,
        relation: response.data.relation,
        perpetrator: response.data.perpetrator,
        showDirections: false,
        locality: response.data.locality,
        persistence: 'remote'
      };

      await this.database.incident.save(incident);

      return incident;
    } catch (e) {
      console.warn(e);
    }
  }
}

const IncidentServicePlugin = {
  install(instance, { database, http }) {
    const service = new IncidentService(database, http);

    instance.prototype.$incidentService = service;
    instance.incidentService = service;
  }
};

export const vueIncidentService = (Vue, options) => {
  Vue.use(IncidentServicePlugin, options);
};

export default (db) => ({
  list() {
    return db.getAll('stopes').catch(() => []);
  },
  get(stopId) {
    return db.get('stopes', stopId).catch(() => null);
  },
  async save(stops) {
    try {
      if (stops.length === 0) return;

      const tx = db.transaction('stopes', 'readwrite');

      stops.forEach((stop) => tx.store.put(stop));

      await tx.done;

      return true;
    } catch (exception) {
      console.error(exception, stops);

      return false;
    }
  },
  clear() {
    return db.clear('stopes');
  }
});

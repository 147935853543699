<template>
  <dialog-select
    v-if="(list || []).length > 1"
    v-model="selected"
    :label="$t('headline.policeInAttendance').toUpperCase()"
    :name="fieldName"
    :items="list"
    :required="required"
    :disabled="disabled"
    :rules="requiredRule"
    prepend-inner-icon="fal fa-users"
    append-icon="fal fa-chevron-down"
    :data-cy="fieldName"
    item-text="title"
    item-value="option"
    no-search
  />
</template>

<script>
import { map, pluck, mergeMap, tap } from 'rxjs/operators';

export default {
  name: 'PoliceInAttendanceSelect',
  props: {
    value: {
      type: String
    },
    kind: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      default: 'police-in-attendance-select'
    },
    required: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      requiredRule: [
        (v) =>
          (this.required && !!v) || this.$t('validations.required', { field: this.$t('headline.policeInAttendance') })
      ]
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  subscriptions() {
    return {
      list: this.$watchAsObservable('kind', {
        immediate: true
      }).pipe(
        pluck('newValue'),
        mergeMap((kind) => this.$incidentKindService.findByKind(kind)),
        map((kind) => kind || {}),
        map((kind) => kind.policeInAttendance || []),
        tap((options) => {
          if (options.length === 1) {
            this.selected = options[0];
          }
        }),
        map((options) =>
          options.map((option) => ({
            option,
            title: this.$t(`incident.policeInAttendance.${option.toLowerCase()}`)
          }))
        )
      )
    };
  }
};
</script>

<template>
  <styled-box v-if="incident" :headline="$router.currentRoute.meta.title" :show-on-mobile="true">
    <template slot="subheadline" v-if="incident.created || incident.updated">
      <div class="additional-info grey--text text-uppercase subtitle-1 mt-2">
        <span class="letter-spacing-2 line-height-1-5">
          <div v-if="incident.created">
            {{ $t('capture.form.transmittedAt') }}
            {{ incident.created | formatDate('dd.MM.yyyy HH:mm') }}
          </div>
          <div v-if="incident.updated">
            {{ $t('capture.form.lastUpdateAt') }}
            {{ incident.updated | formatDate('dd.MM.yyyy HH:mm') }}
          </div>
        </span>
      </div>
    </template>

    <capture-form
      v-model="incident"
      @save="save"
      @remove="confirmDeleteDialog = true"
      @duplicate="createDuplicate"
      :readonly="readonly"
      class="mb-6"
      data-cy="captureForm"
    />

    <styled-snackbar v-if="!incompleteDialog" v-model="incomplete" color="error" icon="fal fa-question-circle" top>
      {{ $t('capture.snackbar.incompleteForm') }}
    </styled-snackbar>

    <v-dialog v-model="incompleteDialog" max-width="500">
      <v-card data-cy="captureDialogIncomplete">
        <v-card-title class="title font-weight-regular">
          {{ $t('capture.dialog.incomplete') }}
        </v-card-title>
        <v-card-actions>
          <v-btn text @click="persist" color="primary" data-cy="save">
            {{ $t('common.save') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel" color="grey darken-2" data-cy="cancel">
            {{ $t('common.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDeleteDialog" max-width="500">
      <v-card data-cy="captureDialogDelete">
        <v-card-title class="title font-weight-regular">
          {{ $t('capture.dialog.deleteTitle') }}
        </v-card-title>
        <v-card-actions>
          <v-btn text @click="remove" color="error" :loading="removeLoading" data-cy="yes">
            {{ $t('common.yes') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDeleteDialog = false" color="grey darken-2" data-cy="no">
            {{ $t('common.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDuplicateDialog" max-width="500">
      <v-card data-cy="captureDialogDuplicate">
        <v-card-title class="title font-weight-regular">
          {{ $t('capture.dialog.duplicate') }}
        </v-card-title>
        <v-card-actions>
          <v-btn text @click="persistDuplicate" color="primary" data-cy="persistDuplicate">
            {{ $t('common.yes') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="showDuplicateDialog = false" color="grey darken-2" data-cy="cancelDuplicate">
            {{ $t('common.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </styled-box>
</template>

<script>
import { format } from 'date-fns';
import { v4 as uuid } from 'uuid';
import CaptureForm from '@/pwa/modules/Capture/components/CaptureForm.vue';
import { switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

export default {
  components: {
    CaptureForm
  },
  data() {
    return {
      incompleteDialog: false,
      confirmDeleteDialog: false,
      showDuplicateDialog: false,
      removeLoading: false,
      incomplete: false,
      readonly: false,
      duplicate: false,
      incident: {
        dateTime: format(Date.now(), 'yyyy-MM-dd HH:mm:ss'),
        incidentLocationType: null,
        branchGroupId: null,
        lineId: null,
        stopId: null,
        destinationStopId: null,
        type: null,
        kind: null,
        relation: null,
        affectedPeople: null,
        perpetrator: null,
        showDirections: false,
        policeInAttendance: null,
        locality: null
      }
    };
  },
  watch: {
    'incident.branchGroupId'(newVal, oldVal) {
      if (oldVal && newVal && oldVal !== newVal) {
        this.incident.lineId = null;
        this.incident.locality = null;
      }
    },
    'incident.incidentLocationType'(newVal, oldVal) {
      // reset dependent fields except after initial load
      if (oldVal !== null && oldVal !== newVal) {
        this.incident.lineId = null;
        this.incident.destinationStopId = null;
        this.incident.locality = null;
      }
    },
    'incident.kind'(newVal, oldVal) {
      if (oldVal && newVal) {
        this.incident.relation = null;
        this.incident.perpetrator = null;
        this.incident.type = null;
        this.incident.affectedPeople = null;
        this.incident.policeInAttendance = null;
      }

      if (!newVal) {
        this.incident.relation = null;
        this.incident.perpetrator = null;
      }
    },
    'incident.locality'(newVal, oldVal) {
      if (oldVal && newVal) {
        this.incident.stopId = null;
      }
    },
    'incident.lineId'(newVal, oldVal) {
      if (oldVal && newVal) {
        this.incident.destinationStopId = null;
        this.incident.stopId = null;
      }
    }
  },
  methods: {
    save(validForm) {
      if (validForm === false) {
        this.incomplete = true;
        this.incompleteDialog = true;
        return;
      }

      if (true === this.duplicate) {
        this.showDuplicateDialog = true;
        return;
      }

      this.incomplete = false;
      this.persist();
    },
    async persist() {
      if (!this.incident.branchGroupId) {
        return;
      }

      this.incident.incidentType = this.incomplete ? 'INCOMPLETE' : 'COMPLETE';
      this.incident.persistence = 'local';
      let isUpdate = true;

      if (this.incident.incidentId === undefined) {
        this.incident.incidentId = uuid();
        isUpdate = false;
      }
      this.incident.update = isUpdate;
      await this.$incidentService.save(this.incident);

      this.incompleteDialog = false;

      let returnRouteName = 'capture';
      if (this.$router.currentRoute.name.includes('archive')) {
        returnRouteName = 'archive';
      }

      this.$router.push({ name: returnRouteName }, function (router) {
        router.meta.snackbar = isUpdate ? 'updated' : 'added';
      });
    },
    async remove() {
      this.removeLoading = true;
      let returnRouteName = 'capture';

      if (this.$router.currentRoute.name.includes('archive')) {
        await this.$incidentService.removeFromBackend({
          incidentId: this.$route.params.id
        });
        returnRouteName = 'archive';
      } else {
        await this.$incidentService.remove(this.incident);
      }

      this.removeLoading = false;
      this.confirmDeleteDialog = false;

      this.$router.push({ name: returnRouteName }, function (router) {
        router.meta.snackbar = 'removed';
      });
    },
    cancel() {
      this.incompleteDialog = false;
      this.incomplete = true;
    },
    createDuplicate() {
      this.duplicate = true;
      this.incident.incidentId = undefined;
      this.incident.dateTime = format(Date.now(), 'yyyy-MM-dd HH:mm:ss');
    },
    persistDuplicate() {
      this.persist();
      this.duplicate = false;
    }
  },
  created() {
    const branches = this.$auth.user() ? this.$auth.user().branchGroups : [];

    if (this.$route.params.id) {
      this.readonly = true;
    }

    if (branches.length === 1 && this.incident) {
      this.incident.branchGroupId = branches[0];
    }

    if (!this.$route.params.id) {
      return;
    }

    this.$incidentService
      .findById(this.$route.params.id)
      .pipe(
        switchMap((incident) => {
          if (!incident) {
            return from(this.$incidentService.fetchById(this.$route.params.id));
          }
          return of(incident);
        })
      )
      .subscribe((incident) => {
        this.incident = {
          ...this.incident,
          ...incident
        };
      });
  }
};
</script>

<template>
  <v-container v-if="isUnavailable" :class="$vuetify.breakpoint.xs ? 'mobile' : ''" class="offline-message" fill-height>
    <v-layout wrap>
      <v-flex xs12 class="align-self-center">
        <v-card-text class="title text-center">
          <span v-if="maintenanceMode">{{ $t('news.maintenanceText') }}</span>
          <span v-else>{{ $t('news.offlineText') }}</span>
          <offline-snackbar />
        </v-card-text>
      </v-flex>
    </v-layout>
  </v-container>

  <styled-box v-else :headline="$t('news.editNews.title')" :showOnMobile="false" class="styled-box-container">
    <news-form v-model="formModel" @submit="onSubmit" :loading="loading" />
  </styled-box>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { NEWS_NAMESPACE, UPDATE_NEWS, PREPARE_UPDATE_NEWS } from '@/shared/modules/News/store';
import OfflineSnackbar from '@/shared/components/OfflineSnackbar';
import NewsForm from '@/shared/modules/News/components/NewsForm';
import UnavailableMixin from '@/pwa/modules/Layout/components/UnavailableMixin.vue';

export default {
  mixins: [UnavailableMixin],
  components: {
    OfflineSnackbar,
    NewsForm
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState(NEWS_NAMESPACE, ['formData']),
    formModel: {
      get() {
        return this.formData;
      },
      set(value) {
        this.setFormData(value);
      }
    }
  },
  methods: {
    ...mapMutations(NEWS_NAMESPACE, ['setFormData']),
    ...mapActions(NEWS_NAMESPACE, { prepareUpdateNews: PREPARE_UPDATE_NEWS, updateNews: UPDATE_NEWS }),
    async onSubmit() {
      this.loading = true;
      if (true === (await this.updateNews())) {
        await this.$router.push({ name: 'news' });
      }
      this.loading = false;
    }
  },
  async mounted() {
    await this.prepareUpdateNews(this.$route.params.newsId);
  }
};
</script>

<style lang="scss" scoped>
.offline-message.mobile {
  color: var(--v-white-base);
  background: url('~@/shared/assets/background-menu.png') no-repeat bottom center;
  background-size: cover;
}
.styled-box-container {
  :deep(.box) {
    padding: 0 !important;
  }
}
</style>

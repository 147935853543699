import { v4 as uuid } from 'uuid';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/store';

export const NEWS_NAMESPACE = 'news';
export const FETCH_UNREAD_COUNT = 'FETCH_UNREAD_COUNT';
export const FETCH_NEWS_LIST = 'FETCH_NEWS_LIST';
export const FETCH_NEWS_DETAILS = 'FETCH_NEWS_DETAILS';
export const READ_SELECTED_NEWS = 'READ_SELECTED_NEWS';
export const PUBLISH_SELECTED_NEWS = 'PUBLISH_SELECTED_NEWS';
export const UNPUBLISH_SELECTED_NEWS = 'UNPUBLISH_SELECTED_NEWS';
export const PREPARE_CREATE_NEWS = 'PREPARE_CREATE_NEWS';
export const CREATE_NEWS = 'CREATE_NEWS';
export const PREPARE_UPDATE_NEWS = 'PREPARE_UPDATE_NEWS';
export const UPDATE_NEWS = 'UPDATE_NEWS';
export const DELETE_NEWS = 'DELETE_NEWS';

const showError = (commit) => {
  commit(
    `${LAYOUT_NAMESPACE}/showGlobalSnackbar`,
    {
      text: 'common.fetchError',
      color: 'error',
      icon: 'fa-times'
    },
    { root: true }
  );
};

export default (Vue) => ({
  namespaced: true,
  state: {
    unreadCount: 0,
    listSearch: '',
    listOptions: {
      page: 1,
      itemsPerPage: 10
    },
    listItems: [],
    listTotal: 0,
    listSelected: null,
    details: null,
    formData: {
      newsId: '',
      publisher: '',
      receivers: [],
      subject: '',
      content: ''
    }
  },
  mutations: {
    setUnreadCount(state, payload) {
      state.unreadCount = payload;
    },
    setListSearch(state, payload) {
      state.listSearch = payload;
    },
    setListOptions(state, payload) {
      state.listOptions = payload;
    },
    setListItems(state, payload) {
      state.listItems = payload;
    },
    setListTotal(state, payload) {
      state.listTotal = payload;
    },
    setListSelected(state, payload) {
      state.listSelected = payload;
    },
    setDetails(state, payload) {
      state.details = payload;
    },
    setFormData(state, payload) {
      state.formData = payload;
    }
  },
  actions: {
    async [FETCH_UNREAD_COUNT]({ commit }) {
      try {
        const response = await Vue.prototype.$http.get('/news-area/news/unread-count');
        await commit('setUnreadCount', response.data);
      } catch (_) {
        showError(commit);
      }
    },
    async [FETCH_NEWS_LIST]({ state, commit }) {
      try {
        const response = await Vue.prototype.$http.get('/news-area/news/list', {
          params: {
            search: state.listSearch,
            page: state.listOptions.page,
            rows_per_page: state.listOptions.itemsPerPage
          }
        });
        commit('setListItems', response.data.items);
        commit('setListTotal', response.data.count);
      } catch (_) {
        showError(commit);
      }
    },
    async [FETCH_NEWS_DETAILS]({ state, commit, dispatch }) {
      try {
        const response = await Vue.prototype.$http.get(`/news-area/news/${state.listSelected}/details`);
        commit('setDetails', response.data);

        if (true !== state.listItems.find((item) => item.id === state.listSelected)?.is_read) {
          await dispatch(READ_SELECTED_NEWS);
        }
      } catch (_) {
        showError(commit);
      }
    },
    async [READ_SELECTED_NEWS]({ state, dispatch, commit }) {
      try {
        await Vue.prototype.$http.post('/commands/news-area/news/read', {
          newsId: state.listSelected
        });
        await dispatch(FETCH_UNREAD_COUNT);
        await dispatch(FETCH_NEWS_LIST);
      } catch (_) {
        showError(commit);
      }
    },
    async [PUBLISH_SELECTED_NEWS]({ state, dispatch, commit }) {
      try {
        await Vue.prototype.$http.post('/commands/news-area/news/publish', {
          newsId: state.listSelected
        });
        await dispatch(FETCH_NEWS_LIST);
        await dispatch(FETCH_NEWS_DETAILS);
      } catch (_) {
        showError(commit);
      }
    },
    async [UNPUBLISH_SELECTED_NEWS]({ state, dispatch, commit }) {
      try {
        await Vue.prototype.$http.post('/commands/news-area/news/unpublish', {
          newsId: state.listSelected
        });
        await dispatch(FETCH_NEWS_LIST);
        await dispatch(FETCH_NEWS_DETAILS);
      } catch (_) {
        showError(commit);
      }
    },
    async [PREPARE_CREATE_NEWS]({ commit }) {
      try {
        await commit('setFormData', {
          newsId: uuid(),
          publisher: '',
          receivers: [],
          subject: '',
          content: ''
        });
      } catch (_) {
        showError(commit);
      }
    },
    async [CREATE_NEWS]({ state, dispatch, commit }) {
      try {
        await Vue.prototype.$http.post('/commands/news-area/news/create', state.formData);
        await dispatch(FETCH_NEWS_LIST);
        await commit('setListSelected', state.formData.newsId);
        await dispatch(FETCH_NEWS_DETAILS);
        commit(
          `${LAYOUT_NAMESPACE}/showGlobalSnackbar`,
          {
            text: 'news.createNews.success',
            color: 'warning',
            icon: 'fa-exclamation-triangle'
          },
          { root: true }
        );
        return true;
      } catch (_) {
        showError(commit);
        return false;
      }
    },
    async [PREPARE_UPDATE_NEWS]({ commit }, newsId) {
      try {
        await commit('setFormData', {
          newsId: uuid(),
          publisher: '',
          receivers: [],
          subject: '',
          content: ''
        });
        const response = await Vue.prototype.$http.get(`/news-area/news/${newsId}/details`);
        await commit('setFormData', {
          newsId: newsId,
          publisher: response.data.publisher,
          receivers: response.data.receivers,
          subject: response.data.subject,
          content: response.data.content
        });
      } catch (_) {
        showError(commit);
      }
    },
    async [UPDATE_NEWS]({ state, dispatch, commit }) {
      try {
        await Vue.prototype.$http.post('/commands/news-area/news/update', state.formData);
        await dispatch(FETCH_NEWS_LIST);
        await commit('setListSelected', state.formData.newsId);
        await dispatch(FETCH_NEWS_DETAILS);
        commit(
          `${LAYOUT_NAMESPACE}/showGlobalSnackbar`,
          {
            text: 'news.editNews.success',
            color: 'success',
            icon: 'fa-check'
          },
          { root: true }
        );
        return true;
      } catch (_) {
        showError(commit);
        return false;
      }
    },
    async [DELETE_NEWS]({ dispatch, commit }, newsId) {
      try {
        await Vue.prototype.$http.post('/commands/news-area/news/delete', { newsId });
        await dispatch(FETCH_NEWS_LIST);
        commit(
          `${LAYOUT_NAMESPACE}/showGlobalSnackbar`,
          {
            text: 'news.deleteNews.success',
            color: 'success',
            icon: 'fa-check'
          },
          { root: true }
        );
      } catch (_) {
        showError(commit);
      }
    }
  }
});

<template>
  <v-btn v-if="$route.meta.helpDocument" @click="downloadHelpDocument" data-cy="helpButton" plain icon>
    <v-icon> fal fa-question </v-icon>
  </v-btn>
</template>

<script>
import { saveAs } from 'file-saver';

export default {
  methods: {
    downloadHelpDocument() {
      saveAs(`/pdf/${this.$route.meta.helpDocument}`, this.$route.meta.helpDocument);
    }
  }
};
</script>

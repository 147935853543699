import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

const chunkArray = (inputArray, chunkSize) =>
  inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

class StopService {
  constructor(database, http) {
    this.$data = new BehaviorSubject([]);
    this.database = database;
    this.http = http;

    this.database.stop.list().then((stops) => this.$data.next(stops));
  }

  async fetchAll(allPlaces) {
    try {
      const placeChunks = chunkArray(allPlaces, 40);

      const stops = await Promise.all(
        placeChunks.map(async (places) => {
          const response = await this.http.get(`public-transport-data/place/stops`, {
            params: { places }
          });

          return response.data;
        })
      ).then((responses) => responses.flat());

      await this.database.stop.clear();
      await this.database.stop.save(stops);
      this.$data.next(stops);
    } catch (e) {
      console.warn(e);
    }
  }

  async refresh() {
    return this.$data.next(await this.database.stop.list());
  }

  list() {
    return this.$data;
  }

  findBy(place) {
    return this.$data.pipe(map((stops) => stops.filter((stop) => stop.place === place)));
  }
}

const StopServicePlugin = {
  install(instance, { database, http }) {
    const service = new StopService(database, http);

    instance.prototype.$stopService = service;
    instance.stopService = service;
  }
};

export const vueStopService = (Vue, options) => {
  Vue.use(StopServicePlugin, options);
};

<template>
  <v-dialog v-model="dialogOpen" :fullscreen="isMobile" max-width="390" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator">
        <v-btn icon v-bind="attrs" v-on="on" data-cy="btnShowDescription">
          <v-icon>fal fa-info-circle</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-card :data-cy="`description-dialog-${name}`">
      <v-card-title class="dialog-title flex-nowrap align-start">
        <span>{{ title }}</span>
        <v-spacer />
        <v-btn class="mr-n1" icon @click="dialogOpen = false" data-cy="btnCloseDescriptionDialog">
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider class="mb-4" />
      <v-card-text v-html="description"></v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogOpen: false
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-title {
  word-break: normal;
}
</style>

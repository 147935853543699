import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Qs from 'qs';

const paramsSerializer = function (params) {
  return Qs.stringify(params, { arrayFormat: 'brackets' });
};

const instance = axios.create({
  baseURL: process.env.VUE_APP_SYSTEM_API_URL,
  timeout: 30000,
  paramsSerializer
});

instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

Vue.use(VueAxios, instance);

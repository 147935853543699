import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/es5/locale/de';
import '@fortawesome/fontawesome-pro/css/all.css';
import theme from '@/shared/helper/theme';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: theme,
    options: {
      customProperties: true
    }
  },
  icons: {
    iconfont: 'fa',
    values: {
      clear: 'fal fa-times'
    }
  },
  lang: {
    locales: { de },
    current: 'de'
  }
});

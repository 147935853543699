<template>
  <v-btn class="elevation-0 red-button" v-on="$listeners" v-bind="$attrs">
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'RedButton',
  inheritAttrs: false
};
</script>

<style lang="scss" scoped>
.red-button {
  border-radius: 18px;
  color: var(--v-error-base);
  border: 2px solid var(--v-error-base);
  background-color: var(--v-white-base) !important;
}
</style>

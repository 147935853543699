<template>
  <v-container grid-list-xl :class="{ dense: $vuetify.breakpoint.xsOnly }">
    <v-form ref="form">
      <v-layout wrap v-if="value">
        <v-flex xs12 md6 :class="{ 'pb-0': $vuetify.breakpoint.smAndDown }">
          <v-flex>
            <date-picker v-model="value.dateTime" :disabled="isDisabled" />
          </v-flex>

          <v-flex>
            <time-picker v-model="value.dateTime" :disabled="isDisabled" />
          </v-flex>

          <v-flex>
            <button-group
              v-model="value.incidentLocationType"
              :options="incidentLocationTypeOptions"
              :disabled="isDisabled"
              nullable
              required
              field-name="incident-location-type-toggle"
              headline="headline.incidentLocationType"
              class="mb-4"
            />
          </v-flex>

          <v-flex v-if="authUser('branchGroups').length !== 1">
            <branch-select v-model="value.branchGroupId" :disabled="isDisabled" required />
          </v-flex>

          <template v-if="value.incidentLocationType === 'LINE'">
            <v-flex v-if="value.branchGroupId">
              <line-select v-model="value.lineId" :branch="value.branchGroupId" :disabled="isDisabled" required />
            </v-flex>

            <v-flex v-if="value.lineId">
              <direction-select
                v-model="value.destinationStopId"
                :line="value.lineId"
                :disabled="isDisabled"
                class="mb-4"
              />
            </v-flex>

            <v-flex v-if="value.lineId">
              <stop-select
                v-model="value.stopId"
                :line="value.lineId"
                :destination-stop-id="value.destinationStopId"
                :disabled="isDisabled"
                required
              />
            </v-flex>
          </template>

          <template v-if="value.incidentLocationType === 'STOP'">
            <v-flex v-if="value.branchGroupId">
              <locality-select v-model="value.locality" :branch="value.branchGroupId" :disabled="isDisabled" required
            /></v-flex>

            <v-flex v-if="value.locality">
              <locality-stop-select v-model="value.stopId" :place="value.locality" :disabled="isDisabled" required />
            </v-flex>
          </template>
        </v-flex>

        <v-flex xs12 md6 :class="{ 'pt-0': $vuetify.breakpoint.smAndDown }">
          <template v-if="value.incidentLocationType !== null">
            <v-flex>
              <kind-select
                v-model="value.kind"
                :incidentLocationType="value.incidentLocationType"
                :disabled="isDisabled"
                required
              />
            </v-flex>

            <v-flex v-if="value.kind">
              <relation-select v-model="value.relation" :kind="value.kind" :disabled="isDisabled" required />
            </v-flex>

            <v-flex v-if="value.kind">
              <type-select v-model="value.type" :kind="value.kind" :disabled="isDisabled" required />
            </v-flex>

            <v-flex v-if="value.kind">
              <perpetrator-select v-model="value.perpetrator" :kind="value.kind" :disabled="isDisabled" required />
            </v-flex>

            <v-flex v-if="value.kind">
              <affected-people-select
                v-model="value.affectedPeople"
                :kind="value.kind"
                :disabled="isDisabled"
                required
              />
            </v-flex>

            <v-flex v-if="value.kind">
              <police-in-attendance-select
                v-model="value.policeInAttendance"
                :kind="value.kind"
                :disabled="isDisabled"
                field-name="police-in-attendance-select"
                required
              />
            </v-flex>
          </template>

          <v-container v-if="!this.isDisabled">
            <v-layout wrap>
              <v-flex xs12 lg6>
                <gradiant-button
                  @click="onSave"
                  block
                  :class="{ 'mt-4': $vuetify.breakpoint.xsOnly }"
                  data-cy="btnIncidentSave"
                >
                  {{ $t('common.save') }}
                </gradiant-button>
              </v-flex>

              <v-flex xs12 lg6 v-if="$router.currentRoute.params.id">
                <red-button
                  :to="{
                    name: $router.currentRoute.name.includes('archive') ? 'archive' : 'capture'
                  }"
                  exact
                  block
                  :class="{ 'mt-4': $vuetify.breakpoint.xsOnly }"
                  data-cy="btnIncidentCancel"
                >
                  {{ $t('common.cancel') }}
                </red-button>
              </v-flex>
            </v-layout>
          </v-container>

          <v-container v-if="this.isDisabled">
            <v-layout wrap>
              <v-flex xs12 lg4>
                <gradiant-button
                  @click="toggleReadOnly"
                  block
                  :class="{ 'mt-4': $vuetify.breakpoint.xsOnly }"
                  data-cy="btnIncidentEdit"
                >
                  {{ $t('common.edit') }}
                </gradiant-button>
              </v-flex>

              <v-flex xs12 lg4>
                <outline-button
                  @click="onDuplicate"
                  block
                  :class="{ 'mt-4': $vuetify.breakpoint.xsOnly }"
                  data-cy="btnIncidentDuplicate"
                >
                  {{ $t('capture.duplicate') }}
                </outline-button>
              </v-flex>

              <v-flex xs12 lg4>
                <red-button
                  @click="$emit('remove')"
                  block
                  :class="{ 'mt-4': $vuetify.breakpoint.xsOnly }"
                  data-cy="btnIncidentDelete"
                >
                  {{ $t('common.delete') }}
                </red-button>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import GradiantButton from '@/shared/modules/Layout/components/GradiantButton.vue';
import OutlineButton from '@/shared/modules/Layout/components/OutlineButton.vue';
import RedButton from '@/shared/modules/Layout/components/RedButton.vue';
import DatePicker from '@/pwa/modules/Capture/components/Fields/DatePicker.vue';
import TimePicker from '@/pwa/modules/Capture/components/Fields/TimePicker.vue';
import ButtonGroup from '@/pwa/modules/Capture/components/Fields/ButtonGroup.vue';
import LocalitySelect from '@/pwa/modules/Capture/components/Fields/LocalitySelect.vue';
import BranchSelect from '@/pwa/modules/Capture/components/Fields/BranchSelect.vue';
import LineSelect from '@/pwa/modules/Capture/components/Fields/LineSelect.vue';
import StopSelect from '@/pwa/modules/Capture/components/Fields/StopSelect.vue';
import LocalityStopSelect from '@/pwa/modules/Capture/components/Fields/LocalityStopSelect.vue';
import DirectionSelect from '@/pwa/modules/Capture/components/Fields/DirectionSelect.vue';
import KindSelect from '@/pwa/modules/Capture/components/Fields/KindSelect.vue';
import RelationSelect from '@/pwa/modules/Capture/components/Fields/RelationSelect.vue';
import TypeSelect from '@/pwa/modules/Capture/components/Fields/TypeSelect.vue';
import PerpetratorSelect from '@/pwa/modules/Capture/components/Fields/PerpetratorSelect.vue';
import AffectedPeopleSelect from '@/pwa/modules/Capture/components/Fields/AffectedPeopleSelect.vue';
import PoliceInAttendanceSelect from '@/pwa/modules/Capture/components/Fields/PoliceInAttendanceSelect.vue';
import { authUser } from '@/shared/helper/vue-auth';

export default {
  components: {
    GradiantButton,
    OutlineButton,
    RedButton,
    DatePicker,
    TimePicker,
    ButtonGroup,
    BranchSelect,
    LineSelect,
    StopSelect,
    LocalityStopSelect,
    DirectionSelect,
    KindSelect,
    RelationSelect,
    TypeSelect,
    PerpetratorSelect,
    AffectedPeopleSelect,
    PoliceInAttendanceSelect,
    LocalitySelect
  },
  data() {
    return {
      isDisabled: this.readonly,
      incidentLocationTypeOptions: [
        { label: this.$i18n.t('incident.incidentLocationType.stop'), value: 'STOP' },
        { label: this.$i18n.t('incident.incidentLocationType.line'), value: 'LINE' }
      ]
    };
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleReadOnly() {
      this.isDisabled = !this.isDisabled;
    },
    authUser(property) {
      return authUser(this.$auth, property);
    },
    onSave() {
      this.$emit('save', this.$refs.form.validate());
    },
    onDuplicate() {
      this.isDisabled = !this.isDisabled;
      this.$emit('duplicate');
    }
  }
};
</script>

<style lang="scss" scoped>
.dense :deep(.flex) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
:deep(.flex:empty) {
  display: none;
}
:deep(.v-text-field .v-input__prepend-inner) {
  margin-right: 10px;
}
:deep(.v-text-field .v-input__prepend-inner .v-icon) {
  color: var(--v-primary-base) !important;
}
:deep(.v-input--is-disabled .v-label),
:deep(.v-input--is-disabled input),
:deep(.v-input--is-disabled textarea),
:deep(.v-select .v-chip--disabled),
:deep(.v-select .v-select__selection--disabled),
:deep(.v-btn.v-btn--disabled) {
  color: rgba(0, 0, 0, 0.5) !important;
}

:deep(.v-item--active.v-btn--disabled) {
  background: var(--v-grey-lighten2) !important;
}
</style>

<template>
  <div class="wrapper">
    <v-select v-bind="$attrs" :item-value="itemValue" :item-text="itemText" append-icon=""></v-select>
    <div class="activator pointer" v-if="!$attrs.disabled" @click="onOpen"></div>
    <div class="clearer pointer" v-if="clearable && $attrs.value && !$attrs.disabled" @click="onClear">
      <v-icon>fal fa-times</v-icon>
    </div>

    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      max-width="390"
      scrollable
      attach=".v-application"
    >
      <v-card :class="{ full: $vuetify.breakpoint.xsOnly }" :data-cy="'dialog-' + $attrs.name">
        <v-card-title class="pt-2 pb-0 px-4" v-if="!noSearch">
          <v-text-field
            v-model="search"
            :placeholder="$t('dialogSelect.searchLabel')"
            append-icon="far fa-search"
            class="search"
            hide-details
            clearable
            solo
          ></v-text-field>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-data-iterator
            :items="$attrs.items"
            :search="search"
            disable-pagination
            disable-sort
            hide-default-header
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-list class="py-4">
                <v-list-item-group :value="$attrs.value" color="primary" mandatory light>
                  <v-list-item class="d-none"></v-list-item>
                  <v-list-item
                    v-for="item in props.items"
                    :key="item.index"
                    :value="item[itemValue]"
                    @click="onClick(item[itemValue])"
                  >
                    <slot name="item" v-bind:item="item">
                      <v-list-item-content>
                        <v-list-item-title class="text-truncate">{{ item[itemText] }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action v-if="item.description">
                        <detail-dialog
                          :title="item[itemText]"
                          :description="item.description"
                          :name="item[itemValue]"
                        />
                      </v-list-item-action>
                    </slot>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>

            <template v-slot:no-results>
              <div class="text-center pa-6">
                {{ $t('noSearchResult') }}
              </div>
            </template>

            <template v-slot:no-data>
              <div class="text-center pa-6">
                {{ $t('noSearchResult') }}
              </div>
            </template>

            <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
            <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
              <slot :name="name" v-bind="slotData" />
            </template>
          </v-data-iterator>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DetailDialog from '@/pwa/modules/Capture/components/DetailDialog.vue';

export default {
  components: { DetailDialog },
  props: {
    noSearch: {
      type: Boolean,
      default: false
    },
    itemValue: {
      type: String,
      required: true
    },
    itemText: {
      type: String,
      default: 'title'
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialog: false,
      search: ''
    };
  },
  methods: {
    onOpen() {
      this.dialog = true;
      this.$emit('open');
    },
    onClick(value) {
      this.dialog = false;
      this.search = '';
      this.$emit('input', value);
    },
    onClear() {
      this.$emit('input', '');
    }
  }
};
</script>

<style scoped>
.wrapper {
  position: relative;
}
.activator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.clearer {
  display: inline-block;
  padding: 15px 5px 15px;
  position: absolute;
  top: 0;
  right: 0;
}
:deep(.search .v-input__slot) {
  font-weight: normal;
  padding-left: 0 !important;
  padding-right: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
}
:deep(.search .v-icon) {
  font-size: 1.25rem;
}
:deep(.v-card.full) {
  width: 90vw;
  height: 90vh;
}
:deep(.v-input--is-disabled .v-icon--disabled) {
  color: rgba(0, 0, 0, 0.5) !important;
}
:deep(.v-select__selection) {
  max-width: 80%;
}
</style>

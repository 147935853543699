<template>
  <dialog-select
    v-model="selected"
    :label="$t('common.stop').toUpperCase()"
    :name="fieldName"
    :items="list"
    :required="required"
    :disabled="disabled"
    :rules="requiredRule"
    prepend-inner-icon="fal fa-store-alt"
    append-icon="fal fa-chevron-down"
    item-text="title"
    item-value="id"
    :data-cy="fieldName"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content
        :class="{
          gps: item.neighbor || item.nearest,
          nearest: item.nearest
        }"
      >
        <v-layout row align-center>
          <v-flex :class="item.nearest || item.neighbor ? 'xs10' : 'xs12'" class="px-4">
            <div class="text-truncate">{{ item.title }}</div>
          </v-flex>
          <v-flex xs2 v-if="item.nearest || item.neighbor" class="text-right">
            <v-icon color="accent" class="mr-4" v-if="item.nearest">fal fa-map-marker-alt</v-icon>
            <v-icon color="accent lighten-3" class="mr-4" v-if="item.neighbor">fal fa-map-marker</v-icon>
          </v-flex>
        </v-layout>
      </v-list-item-content>
    </template>
  </dialog-select>
</template>

<script>
import { pluck, mergeMap, map } from 'rxjs/operators';

export default {
  name: 'StopSelect',
  props: {
    value: {
      type: String
    },
    place: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      default: 'place-stop-select'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      requiredRule: [(v) => (this.required && !!v) || this.$t('validations.required', { field: this.$t(this.label) })]
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    scrollToNearest() {
      setTimeout(function () {
        if (document.getElementsByClassName('gps').length > 0) {
          document.getElementsByClassName('gps')[0].scrollIntoView();
        }
      }, 250);
    }
  },
  subscriptions() {
    return {
      list: this.$watchAsObservable('place', { immediate: true }).pipe(
        pluck('newValue'),
        mergeMap((place) =>
          this.$stopService.findBy(place).pipe(
            map((stops) =>
              this.$currentLocationService.getNearestStationByStops(stops, 2, true).sort((a, b) => {
                if (a.distance === undefined && b.distance === undefined) {
                  return a.title.localeCompare(b.title);
                }
              })
            )
          )
        )
      )
    };
  }
};
</script>

<style scoped>
.gps {
  margin-right: -16px;
  padding-right: 16px;
  border-right: 3px solid var(--v-accent-lighten3);
}
.gps.nearest {
  border-right: 3px solid var(--v-accent-base);
}
.v-list-item__content {
  font-size: 1rem;
}
</style>

<template>
  <v-app>
    <content-area>
      <v-layout fill-height wrap>
        <v-flex xs12 sm8 md6>
          <v-layout align-center justify-center fill-height>
            <v-flex shrink>
              <login-form
                :maintenanceMode="maintenanceMode"
                :is-offline="isOffline"
                class="form mb-12 pb-12"
              ></login-form>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm4 md6>
          <div class="background" :class="{ mobile: $vuetify.breakpoint.xsOnly }"></div>
        </v-flex>
      </v-layout>
    </content-area>
    <mobile-landscape-lock></mobile-landscape-lock>
  </v-app>
</template>

<script>
import UnavailableMixin from '@/pwa/modules/Layout/components/UnavailableMixin.vue';
import ContentArea from '@/shared/modules/Layout/components/ContentArea';
import LoginForm from '@/pwa/modules/Login/components/LoginForm';
import MobileLandscapeLock from '@/shared/modules/Layout/components/MobileLandscapeLock';

export default {
  mixins: [UnavailableMixin],
  components: {
    ContentArea,
    LoginForm,
    MobileLandscapeLock
  },
  mounted() {
    this.checkMaintenanceModePeriodically();
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  }
};
</script>

<style scoped>
.form {
  position: relative;
  z-index: 1;
}
.background {
  width: calc(100% + 250px);
  height: 100%;
  margin-left: -250px;
  background: url('~@/shared/assets/background-login.png') no-repeat left 90%;
  background-size: cover;
}
.background.mobile {
  width: 100%;
  height: calc(100% + 500px);
  margin-left: 0;
  margin-top: -250px;
  background: url('~@/shared/assets/background-login-mobile.png') no-repeat top center;
  background-size: cover;
}
>>> .v-snack {
  padding-left: 0 !important;
  padding-top: 0 !important;
}
</style>

<template>
  <v-dialog v-model="timePicker" :disabled="disabled" :close-on-content-click="false" max-width="290">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        :label="$t('common.time').toUpperCase()"
        :value="dateTime | formatTime"
        :disabled="disabled"
        append-icon="fal fa-clock"
        readonly
        data-cy="timePicker"
      ></v-text-field>
    </template>

    <digital-time-picker v-model="time" :futureDisabled="futureDisabled" @ok="timePicker = false"></digital-time-picker>
  </v-dialog>
</template>

<script>
import { format, isFuture, parseISO } from 'date-fns';

export default {
  data() {
    return {
      timePicker: false,
      date: '',
      time: ''
    };
  },
  props: {
    value: {
      type: String,
      default: format(Date.now(), 'yyyy-MM-dd HH:mm:ss')
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dateTime() {
      return `${this.date} ${this.time}`;
    },
    futureDisabled() {
      return isFuture(parseISO(`${this.date} 23:59`));
    }
  },
  methods: {
    setToMax() {
      this.date = format(Date.now(), 'yyyy-MM-dd');
      this.time = format(Date.now(), 'HH:mm');
    }
  },
  watch: {
    date(date) {
      if (isFuture(parseISO(`${date} ${this.time}:00`))) {
        this.setToMax();
      }

      this.$emit('input', `${date} ${this.time}:00`);
    },
    time(time) {
      if (isFuture(parseISO(`${this.date} ${time}:00`))) {
        this.setToMax();
      }

      this.$emit('input', `${this.date} ${time}:00`);
    },
    value: {
      immediate: true,
      handler(value) {
        if (!value) return;

        try {
          const [date, time] = value.split(' ');

          if (!date || !time) return;

          this.date = date.trim();
          this.time = time.trim().slice(0, 5);
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }
};
</script>

<style scoped>
.v-dialog__container {
  display: inline !important;
}
>>> .v-input__icon--append .v-icon {
  color: #009ee0;
}
>>> .theme--light.v-input .is-disabled input,
>>> .date-time-picker input,
>>> .theme--light.v-input:not(.v-input--is-disabled) textarea {
  color: #c1c1c1;
  border: none !important;
}
>>> .picker-header {
  background: linear-gradient(to right, #00cdf2 0%, #009ee0 100%);
}
>>> .v-text-field__slot input {
  font-size: 16px;
}
</style>

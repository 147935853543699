export default {
  project: 'Sicherheitsdatenbank NRW',
  notAvailableOnMobile: 'Diese Funktion steht in der mobilen Ansicht leider nicht zur Verfügung.',
  notAvailableOnMobileLandscape: 'Die App steht auf diesem Gerät nur im Hochformat zur Verfügung.',
  pleaseRotateDevice: 'Bitte das Gerät drehen.',
  rowsPerPage: 'Einträge pro Seite',
  search: 'Suchen',
  noSearchResult: 'Keine Ergebnisse',
  pleaseEnterSearchTerm: 'Bitte Suchbegriff eingeben',
  dialogSelect: {
    searchLabel: 'Auswahl filtern'
  },
  loadingPleaseWait:
    'Es sind neue Fahrplandaten verfügbar. Aktualisierung läuft. Bitte gedulden Sie sich einen Moment.',
  shared: {
    create: 'Anlegen',
    cancel: 'Abbrechen',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    selectAll: 'Alles auswählen',
    oClock: 'Uhr',
    miscellaneous: 'Diverse',
    all: 'Alle',
    yes: 'Ja',
    no: 'Nein',
    finish: 'Fertig',
    save: 'Speichern',
    maintenanceMode: 'Wartungsarbeiten',
    offline: 'Sie sind offline'
  },
  userRoles: {
    reporter: 'Erfasser',
    system_administrator: 'Systemadmin',
    super_administrator: 'Superadmin',
    administrator: 'Admin',
    data_user: 'Datennutzer',
    evaluator: 'Auswerter',
    api_user: 'Benutzer für Standardschnittstelle',
    edk_user: 'Benutzer für EDK',
    csp_user: 'Benutzer für CSP',
    security_radar_user: 'Benutzer für SecurityRadar'
  },
  validations: {
    required: '{field} ist ein Pflichtfeld'
  }
};

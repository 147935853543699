import Vue from 'vue';

import '@/shared/plugins/axios';
import '@/shared/plugins/vue-debounce';
import '@/shared/plugins/vue-scroll-to';
import '@/shared/plugins/vue-observe-visibility.js';
import '@/shared/plugins/tinymce-vue.js';
import '@/shared/plugins/sentry';
import '@/pwa/plugins/vue-auth';
import '@/pwa/plugins/vueRx';
import '@/pwa/registerServiceWorker';

import DialogSelect from '@/shared/components/DialogSelect';
import DigitalTimePicker from '@/shared/components/DigitalTimePicker';
import StyledBox from '@/shared/components/StyledBox';
import StyledDataTable from '@/shared/components/StyledDataTable';
import StyledSnackbar from '@/shared/components/StyledSnackbar';

import { formatDate, formatTime, formatNumber } from '@/shared/filter';

import vuetify from '@/shared/helper/vuetify';
import router from '@/pwa/helper/router';
import i18n from '@/pwa/helper/i18n';
import store from '@/pwa/helper/store';
import App from '@/pwa/App.vue';

import { initDB, vueDB } from '@/pwa/plugins/database/database';
import {
  vueBranchService,
  vueLineService,
  vueStopService,
  vuePlaceService,
  vueIncidentKindService,
  vueIncidentService,
  vueCurrentLocationService,
  vueImportService
} from '@/pwa/modules/Capture/services';

Vue.config.productionTip = false;

Vue.component('DialogSelect', DialogSelect);
Vue.component('DigitalTimePicker', DigitalTimePicker);
Vue.component('StyledBox', StyledBox);
Vue.component('StyledDataTable', StyledDataTable);
Vue.component('StyledSnackbar', StyledSnackbar);

Vue.filter('formatDate', formatDate);
Vue.filter('formatTime', formatTime);
Vue.filter('formatNumber', formatNumber);

(async function () {
  const database = await initDB();

  vueDB(Vue, database);
  vueBranchService(Vue, { database, http: Vue.prototype.$http });
  vueLineService(Vue, { database, http: Vue.prototype.$http });
  vueStopService(Vue, { database, http: Vue.prototype.$http });
  vuePlaceService(Vue, { database, http: Vue.prototype.$http });
  vueIncidentService(Vue, { database, http: Vue.prototype.$http });
  vueIncidentKindService(Vue, { database, http: Vue.prototype.$http });
  vueImportService(Vue, { http: Vue.prototype.$http });
  vueCurrentLocationService(Vue);

  new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: (h) => h(App)
  }).$mount('#app');
})();

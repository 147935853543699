<template>
  <v-app-bar app flat clipped-left color="white">
    <v-layout align-center>
      <v-flex shrink>
        <div class="logo-wrapper pl-1" :class="{ mini: miniSidebar }">
          <v-img
            :src="require('@/shared/assets/logos/kcs.svg')"
            position="left center"
            height="40"
            contain
            alt="KCS Logo"
          ></v-img>
        </div>
      </v-flex>
      <v-flex grow>
        <v-layout align-center>
          <v-flex offset-xs4 xs4>
            <transition name="fade">
              <div class="text-center text-uppercase text-truncate letter-spacing-3" v-if="showToolbarTitle">
                {{ $route.meta.title }}
              </div>
            </transition>
          </v-flex>
          <v-flex grow class="text-right">
            <v-layout align-center justify-end v-if="$route.meta.showGlobalSearch">
              <v-flex grow>
                <div class="search-wrapper">
                  <transition name="slide">
                    <v-text-field
                      v-if="globalSearchActive"
                      v-debounce:500="onSearch"
                      :value="globalSearchTermModel"
                      class="search-field white"
                      data-cy="globalSearchField"
                      autofocus
                    ></v-text-field>
                  </transition>
                </div>
              </v-flex>
              <v-flex shrink>
                <search-button :active="globalSearchActive" @click="toggleGlobalSearch" />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex shrink>
        <help-button />
      </v-flex>
      <v-flex shrink>
        <news-button />
      </v-flex>
      <v-flex shrink>
        <account-menu />
      </v-flex>
    </v-layout>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/store';
import SearchButton from '@/shared/modules/Layout/components/SearchButton';
import HelpButton from '@/shared/modules/Layout/components/HelpButton';
import NewsButton from '@/shared/modules/Layout/components/NewsButton';
import AccountMenu from '@/shared/modules/Layout/components/AccountMenu';

export default {
  components: {
    SearchButton,
    HelpButton,
    NewsButton,
    AccountMenu
  },
  computed: {
    ...mapState(LAYOUT_NAMESPACE, ['miniSidebar', 'showToolbarTitle', 'globalSearchActive', 'globalSearchTerm']),
    globalSearchTermModel: {
      get() {
        return this.globalSearchTerm;
      },
      set(value) {
        this.setGlobalSearchTerm(value);
      }
    }
  },
  methods: {
    ...mapMutations(LAYOUT_NAMESPACE, ['toggleMenu', 'toggleGlobalSearch', 'setGlobalSearchTerm']),
    onSearch(search) {
      this.globalSearchTermModel = search;
    }
  }
};
</script>

<style scoped>
>>> .v-toolbar__content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.logo-wrapper {
  width: 60px;
}
.search-wrapper {
  width: 100%;
  height: 33px;
  position: relative;
}
.search-field {
  width: 100%;
  position: absolute;
  right: 0;
}
.search-field >>> .v-text-field__details {
  display: none;
}
.slide-enter-active,
.slide-leave-active {
  transition: width 0.5s ease-in-out;
  overflow: hidden;
}
.slide-enter,
.slide-leave-to {
  width: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

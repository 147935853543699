<template>
  <v-app-bar app flat fixed color="white">
    <div class="icon-wrapper pointer pr-6" @click="toggleMenu" data-cy="toggleMenu">
      <v-icon> far fa-align-right </v-icon>
    </div>
    <v-spacer />
    <div class="search-wrapper text-center">
      <transition name="slide">
        <v-text-field
          v-if="$route.meta.showGlobalSearch && globalSearchActive"
          v-debounce:500="onSearch"
          :value="globalSearchTermModel"
          class="search-field white"
          autofocus
        ></v-text-field>
      </transition>
      <transition name="fade">
        <span class="grey--text subtitle-1 text-uppercase" v-if="showToolbarTitle">
          <span class="letter-spacing-3">
            {{ this.$route.meta.title }}
          </span>
        </span>
      </transition>
    </div>
    <v-spacer />
    <search-button :active="globalSearchActive" @click="toggleGlobalSearch" />
    <help-button />
    <news-button />
    <account-menu />
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/store';
import SearchButton from '@/shared/modules/Layout/components/SearchButton';
import AccountMenu from '@/shared/modules/Layout/components/AccountMenu';
import NewsButton from '@/shared/modules/Layout/components/NewsButton';
import HelpButton from '@/shared/modules/Layout/components/HelpButton';

export default {
  components: {
    SearchButton,
    HelpButton,
    NewsButton,
    AccountMenu
  },
  computed: {
    ...mapState(LAYOUT_NAMESPACE, ['showMenu', 'showToolbarTitle', 'globalSearchActive', 'globalSearchTerm']),
    globalSearchTermModel: {
      get() {
        return this.globalSearchTerm;
      },
      set(value) {
        this.setGlobalSearchTerm(value);
      }
    }
  },
  methods: {
    ...mapMutations(LAYOUT_NAMESPACE, ['toggleMenu', 'toggleGlobalSearch', 'setGlobalSearchTerm']),
    onSearch(search) {
      this.globalSearchTermModel = search;
    }
  }
};
</script>

<style scoped>
.v-app-bar {
  z-index: 1;
}
>>> .v-toolbar__content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.search-wrapper {
  line-height: 56px;
  width: 100%;
  height: 56px;
  position: relative;
}
.search-field {
  width: 100%;
  position: absolute;
  top: 12px;
  right: 0;
}
.search-field >>> .v-text-field__details {
  display: none;
}
.icon-wrapper {
  width: 50px;
}
.slide-enter-active,
.slide-leave-active {
  transition: width 0.5s ease-in-out;
  overflow: hidden;
}
.slide-enter,
.slide-leave-to {
  width: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <div
    :class="{
      desktop: $vuetify.breakpoint.smAndUp,
      mobile: $vuetify.breakpoint.xs,
      isUnavailable: isUnavailable
    }"
    class="wrapper"
  >
    <styled-box
      :headline="$router.currentRoute.meta.title"
      :showOnMobile="true"
      v-scroll="onScroll"
      v-if="!isUnavailable"
    >
      <styled-data-table
        :headers="tableHeader"
        :items="tableData"
        :items-per-page="-1"
        :sort-by.sync="sortBy"
        :sort-desc.sync="descending"
        :no-results-text="$t('noSearchResult')"
        :hide-default-header="$vuetify.breakpoint.xsOnly"
        hide-default-footer
        disable-sort
        data-cy="archiveList"
      >
        <template slot="item" slot-scope="props">
          <tr
            :key="props.item.id"
            @click="
              $router.push({
                name: 'archive-details',
                params: { id: props.item.id }
              })
            "
            class="pointer"
          >
            <td class="hidden-md-and-up py-2 pointer">
              <div class="font-weight-medium">
                <span v-if="lineTitles[props.item.line_id]"> Linie {{ lineTitles[props.item.line_id] }}, </span>
                {{ stopTitles[props.item.stop_id] }}
              </div>
              <div class="grey--text">
                {{ props.item.date_time | formatDate('dd.MM.yyyy HH:mm') }}
              </div>
            </td>
            <td class="grey--text hidden-sm-and-down">
              {{ props.item.date_time | formatDate('dd.MM.yyyy HH:mm') }}
            </td>
            <td class="hidden-md-and-down">
              {{ props.item.locality }}
            </td>
            <td class="hidden-sm-and-down">
              {{ stopTitles[props.item.stop_id] || '' }}
            </td>
            <td class="hidden-sm-and-down">
              {{ lineTitles[props.item.line_id] || '' }}
            </td>
            <td class="hidden-xs-only">
              <span v-if="props.item.kind">
                {{ $t(`incident.kind.${props.item.kind.toLowerCase()}.title`) }}
              </span>
            </td>
            <td class="hidden-md-and-down">
              {{ branchTitles[props.item.branch_group_id] || '' }}
            </td>
            <td class="text-right">
              <v-icon color="error" v-if="props.item.incident_type === 'INCOMPLETE'"> fal fa-file-minus </v-icon>
              <v-icon color="warning" v-else-if="props.item.similar_id !== null"> fal fa-file-check </v-icon>
              <v-icon color="primary" v-else>fal fa-file-check</v-icon>
            </td>
            <td class="text-right">
              <v-icon color="grey">fal fa-chevron-right</v-icon>
            </td>
          </tr>
        </template>
      </styled-data-table>

      <div v-if="loading" class="mt-3 text-center">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
      </div>

      <to-top-button />
    </styled-box>

    <v-container fill-height v-if="isUnavailable">
      <v-layout row wrap>
        <v-flex xs12 class="align-self-center">
          <v-card-text class="title text-center" :class="{ 'white--text': $vuetify.breakpoint.xsOnly }">
            <span v-if="maintenanceMode">{{ $t('archiveList.maintenanceText') }}</span>
            <span v-else>{{ $t('archiveList.offlineText') }}</span>
          </v-card-text>
        </v-flex>
      </v-layout>
    </v-container>

    <offline-snackbar />

    <styled-snackbar :value="snackbar === 'removed'" color="error" top>
      {{ $t('archiveList.snackbar.incidentDeleted') }}
    </styled-snackbar>

    <styled-snackbar :value="snackbar === 'updated'" color="success" top>
      {{ $t('archiveList.snackbar.incidentUpdated') }}
    </styled-snackbar>
  </div>
</template>

<script>
import { map } from 'rxjs/operators';
import { mapState, mapMutations } from 'vuex';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/store';
import ToTopButton from '@/shared/components/ToTopButton';
import OfflineSnackbar from '@/shared/components/OfflineSnackbar';
import UnavailableMixin from '@/pwa/modules/Layout/components/UnavailableMixin.vue';

export default {
  components: {
    ToTopButton,
    OfflineSnackbar
  },
  mixins: [UnavailableMixin],
  data() {
    return {
      tableHeader: [
        {
          text: `${this.$t('archiveList.line')}/${this.$t('archiveList.stop')}`,
          class: 'hidden-md-and-up'
        },
        {
          text: `${this.$t('archiveList.date')}/${this.$t('archiveList.time')}`,
          value: 'date_time',
          class: 'hidden-sm-and-down'
        },
        {
          text: this.$t('archiveList.locality'),
          value: 'locality',
          class: 'hidden-md-and-down'
        },
        {
          text: this.$t('archiveList.stop'),
          value: 'stop_id',
          class: 'hidden-sm-and-down'
        },
        {
          text: this.$t('archiveList.line'),
          value: 'line_id',
          class: 'hidden-sm-and-down'
        },
        {
          text: this.$t('archiveList.incident'),
          value: 'kind',
          class: 'hidden-xs-only'
        },
        {
          text: this.$t('archiveList.branch'),
          value: 'branch_id',
          class: 'hidden-md-and-down'
        },
        {
          text: ''
        },
        {
          text: ''
        }
      ],
      tableData: [],
      rowsPerPage: 50,
      page: 1,
      totalPages: 1,
      loading: false,
      snackbar: ''
    };
  },
  computed: {
    ...mapState(LAYOUT_NAMESPACE, ['globalSearchTerm']),
    search: {
      get() {
        return this.$route.query.search || '';
      },
      set(search) {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            search
          }
        });
      }
    },
    sortBy: {
      get() {
        return this.$route.query.sortBy || 'date_time';
      },
      set(sortBy) {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            sortBy
          }
        });
      }
    },
    descending: {
      get() {
        return this.$route.query.descending || true;
      },
      set(descending) {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            descending
          }
        });
      }
    }
  },
  methods: {
    ...mapMutations(LAYOUT_NAMESPACE, ['setGlobalSearchTerm']),
    async getIncidents(page = 1) {
      if (page !== 1 && (this.loading || page > this.totalPages)) {
        return;
      }

      this.page = page;
      this.loading = true;

      let url = `/incident-capture/incident/list?search=${this.search}&page=${this.page}&rows_per_page=${
        this.rowsPerPage
      }&sort_by=${this.sortBy}&sorting=${this.descending ? 'desc' : 'asc'}`;

      const result = await this.$http.get(url);
      this.loading = false;

      if (result.status !== 200) {
        return;
      }

      if (page > 1) {
        this.tableData = this.tableData.concat(result.data.incidents);
        return;
      }

      this.tableData = result.data.incidents;
      this.totalPages = result.data.pages;
    },
    onScroll() {
      if (typeof window === 'undefined' || window.innerHeight + window.scrollY < document.body.offsetHeight) {
        return;
      }

      this.getIncidents(this.page + 1);
    }
  },
  subscriptions() {
    return {
      lineTitles: this.$lineService.list().pipe(
        map((lines) =>
          lines.reduce((obj, line) => {
            obj[line.id] = line.title;

            return obj;
          }, {})
        )
      ),
      stopTitles: this.$stopService.list().pipe(
        map((lines) =>
          lines.reduce((obj, stop) => {
            obj[stop.id] = stop.title;

            return obj;
          }, {})
        )
      ),
      branchTitles: this.$branchService.list().pipe(
        map((lines) =>
          lines.reduce((obj, branch) => {
            obj[branch.id] = branch.title;

            return obj;
          }, {})
        )
      )
    };
  },
  watch: {
    globalSearchTerm(value) {
      this.search = value;
      this.getIncidents();
    },
    options: {
      handler() {
        this.getIncidents();
      },
      deep: true
    },
    isUnavailable(value) {
      if (!value) {
        this.getIncidents();
      }
    }
  },
  mounted() {
    if (!this.isUnavailable) {
      this.getIncidents();
    }
    this.snackbar = this.$route.meta.snackbar;
    this.$route.meta.snackbar = '';
  }
};
</script>

<style scoped>
.wrapper {
  height: 100%;
}
.mobile.isUnavailable {
  background: url('~@/shared/assets/background-menu.png') no-repeat bottom center;
  background-size: cover;
}
.mobile .caputure-hint {
  color: var(--v-white-base);
  justify-content: center !important;
}
.desktop .caputure-hint {
  color: var(--v-black-base);
  justify-content: center !important;
}
.mobile >>> .to-top-button {
  bottom: 4.75rem;
}
>>> .v-data-table .v-data-table-header th.sortable .v-icon {
  margin-left: 10px;
}
</style>

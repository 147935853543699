<template>
  <styled-snackbar
    :value="globalSnackbar.visible"
    :color="globalSnackbar.color"
    :icon="globalSnackbar.icon"
    :timeout="-1"
    top
  >
    {{ $t(globalSnackbar.text) }}
  </styled-snackbar>
</template>

<script>
import { mapState } from 'vuex';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/store';

export default {
  computed: {
    ...mapState(LAYOUT_NAMESPACE, ['globalSnackbar'])
  }
};
</script>

<template>
  <v-btn v-if="$route.meta.showGlobalSearch" @click="$emit('click')" data-cy="searchButton" plain icon>
    <v-icon :color="active ? 'primary' : ''"> far fa-search </v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
};
</script>

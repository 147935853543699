class CurrentLocationService {
  constructor() {
    this.$location = false;
    this.$watcher = false;
  }

  startWatching() {
    const options = {
      enableHighAccuracy: false,
      maximumAge: 30 * 1000
    };

    if (this.$watcher) {
      navigator.geolocation.clearWatch(this.$watcher);
    }
    this.$watcher = navigator.geolocation.watchPosition(
      CurrentLocationService.setCurrentLocation,
      CurrentLocationService.positionError,
      options
    );
  }

  static setCurrentLocation(location) {
    CurrentLocationService.$location = location;
  }

  static positionError(error) {
    console.warn('CurrentLocationServiceError', error);
  }

  getNearestStationByStops(stopData, limit = 0, sortByDistance = false) {
    if (false === CurrentLocationService.$location) {
      return stopData;
    }
    stopData = CurrentLocationService.clearStopDataMarkers(stopData);
    let nearestIndex = 0;
    try {
      let nearestStation = CurrentLocationService.findNearestStation(
        CurrentLocationService.$location.coords.latitude,
        CurrentLocationService.$location.coords.longitude,
        stopData
      );

      if (sortByDistance) {
        stopData = CurrentLocationService.calculateDistanceOfStations(
          CurrentLocationService.$location.coords.latitude,
          CurrentLocationService.$location.coords.longitude,
          stopData
        );

        stopData.sort((a, b) => (a.distance > b.distance ? 1 : b.distance > a.distance ? -1 : 0));
      }

      stopData.map(function (stop, index) {
        if (stop.id === nearestStation.id) {
          stop.nearest = true;
          stop.nearestIndex = index;
          nearestIndex = index;
        }
        return stop;
      });

      if (limit) {
        stopData = CurrentLocationService.markNeighborStops(stopData, nearestIndex, limit);
      }

      return stopData;
    } catch (error) {
      console.warn('nearest stop not found', error);
    }

    return stopData;
  }

  static clearStopDataMarkers(stopData) {
    stopData.forEach(function (stop, index) {
      stopData[index].nearest = false;
      stopData[index].nearestIndex = false;
      stopData[index].neighbor = false;
    });

    return stopData;
  }

  static markNeighborStops(stopData, index, limit) {
    for (let i = index - limit; i <= index + limit; i++) {
      if (stopData[i] !== undefined && index !== i) {
        stopData[i].neighbor = true;
      }
    }

    return stopData;
  }

  static calculateDistanceOfStations(longitude, latitude, stations) {
    for (let index = 0; index < stations.length; ++index) {
      stations[index].distance = CurrentLocationService.pythagorasEquirectangular(
        latitude,
        longitude,
        stations[index].latitude,
        stations[index].longitude
      );
    }

    return stations;
  }

  static findNearestStation(longitude, latitude, stations) {
    let minDiff = 9999;
    let closest;

    for (let index = 0; index < stations.length; ++index) {
      let diff = CurrentLocationService.pythagorasEquirectangular(
        latitude,
        longitude,
        stations[index].latitude,
        stations[index].longitude
      );
      if (diff < minDiff) {
        closest = index;
        minDiff = diff;
      }
    }

    return stations[closest];
  }

  static deg2Rad(deg) {
    return (deg * Math.PI) / 180;
  }

  static pythagorasEquirectangular(lat1, lon1, lat2, lon2) {
    const R = 6371;

    lat1 = CurrentLocationService.deg2Rad(lat1);
    lat2 = CurrentLocationService.deg2Rad(lat2);
    lon1 = CurrentLocationService.deg2Rad(lon1);
    lon2 = CurrentLocationService.deg2Rad(lon2);

    let x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
    let y = lat2 - lat1;

    return Math.sqrt(x * x + y * y) * R;
  }
}

const CurrentLocationServicePlugin = {
  install(instance) {
    const service = new CurrentLocationService();

    instance.prototype.$currentLocationService = service;
    instance.currentLocationService = service;
  }
};

export const vueCurrentLocationService = (Vue, options) => {
  Vue.use(CurrentLocationServicePlugin, options);
};

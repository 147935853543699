<template>
  <styled-snackbar
    :value="isUnavailable"
    :timeout="-1"
    :top="$vuetify.breakpoint.smAndUp"
    :bottom="$vuetify.breakpoint.xsOnly"
    :class="{
      mobile: $vuetify.breakpoint.xsOnly,
      'elevation-0': $vuetify.breakpoint.xsOnly
    }"
    icon="fal fa-exclamation-circle"
    color="secondary"
    class="offline-snackbar"
  >
    <span v-if="maintenanceMode">{{ $t('shared.maintenanceMode') }}</span>
    <span v-else>{{ $t('shared.offline') }}</span>
  </styled-snackbar>
</template>

<script>
import UnavailableMixin from '@/pwa/modules/Layout/components/UnavailableMixin.vue';

export default {
  mixins: [UnavailableMixin]
};
</script>

<style scoped>
.offline-snackbar.mobile {
  left: 0;
  right: 0;
  bottom: 60px;
  z-index: 3;
}
.offline-snackbar.mobile >>> .v-snack__content {
  width: 100vw;
}
</style>

<template>
  <dialog-select
    v-model="selected"
    :label="$t('common.place').toUpperCase()"
    :name="fieldName"
    :items="list"
    :required="required"
    :disabled="disabled"
    :rules="requiredRule"
    prepend-inner-icon="fal fa-map-marker-alt"
    append-icon="fal fa-chevron-down"
    item-text="title"
    item-value="id"
    :data-cy="fieldName"
  ></dialog-select>
</template>

<script>
import { map, pluck, mergeMap } from 'rxjs/operators';

export default {
  name: 'PlaceSelect',
  props: {
    value: {
      type: String
    },
    branch: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      default: 'place-select'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      requiredRule: [
        (v) => (this.required && !!v) || this.$t('validations.required', { field: this.$t('common.place') })
      ]
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  subscriptions() {
    return {
      list: this.$watchAsObservable('branch', { immediate: true }).pipe(
        pluck('newValue'),
        mergeMap((branch) => this.$placeService.findBy(branch)),
        map((places) =>
          places.map((place) => ({ ...place, id: place.title })).sort((a, b) => a.title.localeCompare(b.title))
        )
      )
    };
  }
};
</script>

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

class PlaceService {
  constructor(database, http) {
    this.$data = new BehaviorSubject([]);
    this.database = database;
    this.http = http;

    this.database.place.list().then((places) => this.$data.next(places));
  }

  async fetchAll(branchIds) {
    try {
      const results = await Promise.all(
        branchIds.map(async (id) => {
          const response = await this.http.get(`public-transport-data/branch-group/${id}/places`);

          return response.data.map((place) => {
            return { title: place, branches: [id] };
          });
        })
      );

      const places = results.flat();

      await this.database.place.clear();
      await this.database.place.save(places);
      this.$data.next(places);

      return places;
    } catch (e) {
      console.warn(e);
    }
  }

  async refresh() {
    return this.$data.next(await this.database.place.list());
  }

  list() {
    return this.$data;
  }

  findBy(branch) {
    return this.$data.pipe(map((list) => list.filter((place) => place.branches.includes(branch))));
  }
}

const PlaceServicePlugin = {
  install(instance, { database, http }) {
    const service = new PlaceService(database, http);

    instance.prototype.$placeService = service;
    instance.placeService = service;
  }
};

export const vuePlaceService = (Vue, options) => {
  Vue.use(PlaceServicePlugin, options);
};

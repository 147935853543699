<template>
  <v-menu v-model="menu" offset-y>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" plain icon>
        <v-icon> fal fa-user-circle </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-action>
            <v-avatar color="grey" size="38" class="mt-1">
              <span class="white--text title">{{ userData.avatar }}</span>
            </v-avatar>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ userData.username }}</v-list-item-title>
            <v-list-item-subtitle>{{ userData.role }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item :to="{ name: 'account-password-change' }">
          <v-list-item-action>
            <v-icon>fas fa-key</v-icon>
          </v-list-item-action>
          <v-list-item-title>
            {{ $t('account.passwordChange') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      menu: false
    };
  },
  computed: {
    userData() {
      const result = {
        avatar: '',
        username: '',
        role: ''
      };

      if (!this.$auth.user()) {
        return result;
      }

      result.avatar = this.$auth.user().username.charAt(0).toUpperCase();
      result.username = this.$auth.user().username;

      if (!this.$auth.user().roles || this.$auth.user().roles.length < 1) {
        return result;
      }

      result.role = this.$t(`userRoles.${this.$auth.user().roles[0].toLowerCase().replace('role_', '')}`);

      return result;
    }
  }
};
</script>

<style scoped>
.v-list {
  padding: 0;
}
.v-list-item {
  color: var(--v-grey-base);
  height: 64px;
}
.v-list-item--active:before {
  background-color: transparent;
}
.v-list-item__action {
  justify-content: center;
  min-width: 48px;
  margin-right: 15px !important;
}
.v-list-item__action .v-icon {
  color: var(--v-grey-base);
}
.v-list-item__content {
  font-size: 16px;
}
.v-list-item__content .v-list-item__title {
  color: var(--v-grey-base);
}
.v-list-item--active .v-list-item__title,
.v-list-item--active .v-icon {
  color: var(--v-primary-base);
}
.v-list-item--active.v-list-item--disabled .v-list-item__title,
.v-list-item--active.v-list-item--disabled .v-icon {
  color: var(--v-grey-base);
}
</style>

<template>
  <v-container fluid fill-height :class="{ 'not-available': !showOnMobile && $vuetify.breakpoint.xsOnly }">
    <v-layout align-center fill-height v-if="!showOnMobile && $vuetify.breakpoint.xsOnly">
      <v-flex class="text-center pa-4">
        <div class="title white--text">
          {{ $t('notAvailableOnMobile') }}
        </div>
      </v-flex>
    </v-layout>

    <v-layout column fill-height v-else>
      <v-flex shrink class="text-center pt-2" v-if="headline">
        <h1 class="display-1 text-uppercase">
          <span class="letter-spacing-3">{{ headline }}</span>
        </h1>
        <slot name="subheadline"></slot>
      </v-flex>
      <v-flex class="box" :class="$vuetify.breakpoint.xsOnly ? 'mobile pt-4' : 'ma-6 pa-6'">
        <slot></slot>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    headline: {
      type: String,
      required: false
    },
    showOnMobile: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped>
.not-available {
  background: url('~@/shared/assets/background-menu.png') no-repeat bottom center;
  background-size: cover;
}
.container {
  height: 100%;
}

.container + .container {
  margin-top: -24px !important;
}

.container + .container .flex {
  margin-top: 0 !important;
}

.box {
  background-color: var(--v-white-base);
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.box.mobile {
  border: none !important;
}
</style>

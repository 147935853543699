import Vue from 'vue';
import Vuex from 'vuex';
import layout, { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/store';
import account, { ACCOUNT_NAMESPACE } from '@/shared/modules/Account/store';
import news, { NEWS_NAMESPACE } from '@/shared/modules/News/store';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({ layout: { miniSidebar: state.layout.miniSidebar } })
});

export default new Vuex.Store({
  modules: {
    [LAYOUT_NAMESPACE]: layout(Vue),
    [ACCOUNT_NAMESPACE]: account(Vue),
    [NEWS_NAMESPACE]: news(Vue)
  },
  plugins: [vuexLocal.plugin]
});

class ImportService {
  constructor(http) {
    this.http = http;
  }

  async shouldImport(user) {
    try {
      const lastLocalImport = window.localStorage.getItem('sidaba-last-import');
      const lastLocalBranches = window.localStorage.getItem('sidaba-user-branches');

      const lastBackendImport = await this.lastImportId();
      const lastUserBranches = JSON.stringify(user.branchGroups);

      if (!lastBackendImport || !lastLocalImport || !lastLocalBranches) {
        this.lastImport = lastBackendImport;
        this.lastBranches = JSON.stringify(user.branchGroups);

        return true;
      }

      const shouldUpdate = lastBackendImport !== lastLocalImport || lastUserBranches !== lastLocalBranches;

      if (!shouldUpdate) return false;

      this.lastImport = lastBackendImport;
      this.lastBranches = JSON.stringify(user.branchGroups);

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  updateLastImport() {
    window.localStorage.setItem('sidaba-last-import', this.lastImport);
    window.localStorage.setItem('sidaba-user-branches', this.lastBranches);
  }

  async lastImportId() {
    const response = await this.http.get(`dino-import/list`);

    const lastImport = response.data
      .filter((item) => item.state === 'completed')
      .sort((a, b) => b.completed.localeCompare(a.completed));

    if (lastImport.length === 0) {
      return null;
    }

    return lastImport[0].id;
  }
}

const ImportServicePlugin = {
  install(instance, { http }) {
    const service = new ImportService(http);

    instance.prototype.$importService = service;
    instance.importService = service;
  }
};

export const vueImportService = (Vue, options) => {
  Vue.use(ImportServicePlugin, options);
};

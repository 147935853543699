<template>
  <v-data-iterator
    :items="items"
    :options.sync="syncOptions"
    :server-items-length="total"
    :footer-props="{ itemsPerPageOptions: [], prevIcon: 'fal fa-chevron-left', nextIcon: 'fal fa-chevron-right' }"
    disable-sort
  >
    <template v-slot:header>
      <div class="header">
        <v-text-field
          v-debounce:500="(value) => $emit('search-changed', value)"
          :placeholder="$t('news.showNews.newsList.search')"
          prepend-inner-icon="fal fa-search"
          class="search pa-2"
          data-cy="searchTextField"
          hide-details
          solo
          flat
        />
        <v-progress-linear v-if="loading" class="loading" indeterminate></v-progress-linear>
      </div>
    </template>

    <template v-slot:default="{ items }">
      <v-list color="transparent" class="pa-0" data-cy="list">
        <v-list-item-group v-model="listModel" :mandatory="$vuetify.breakpoint.smAndUp">
          <template v-for="(item, index) in items">
            <v-list-item :value="item.id" :key="index" :class="{ unread: !item.is_read }" class="py-3 px-6">
              <v-list-item-avatar color="primary">
                <span class="white--text font-weight-medium" data-cy="list-publisher">{{ item.publisher }}</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle v-if="showAdminFeatures" data-cy="list-info">
                  <span v-if="true === item.is_published" class="primary--text">({{ $t('news.active') }})</span>
                  <span v-if="false === item.is_published" class="error--text">({{ $t('news.inactive') }})</span>
                  <span class="black--text ml-3">
                    {{ translateReceivers(item.receivers) }}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-title data-cy="list-subject">{{ item.subject }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="align-self-start">
                <v-list-item-action-text class="datetime">
                  {{ formatDate(item.date) }}
                </v-list-item-action-text>
                <div v-if="showAdminFeatures && $vuetify.breakpoint.mdAndUp" class="actions">
                  <v-btn @click.stop="$emit('edit', item.id)" data-cy="list-edit" plain small icon>
                    <v-icon small>fal fa-edit</v-icon>
                  </v-btn>
                  <v-dialog v-model="deleteDialogs[item.id]" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" data-cy="list-delete" plain small icon>
                        <v-icon small>fal fa-trash</v-icon>
                      </v-btn>
                    </template>
                    <v-card data-cy="deleteDialog">
                      <v-card-title class="text-h5">
                        {{
                          item.is_published
                            ? $t('news.showNews.newsList.delete.cantDelete')
                            : $t('news.showNews.newsList.delete.reallyDelete')
                        }}
                      </v-card-title>
                      <v-card-text>
                        {{
                          item.is_published
                            ? $t('news.showNews.newsList.delete.youCanNotDelete')
                            : $t('news.showNews.newsList.delete.doYouWantDoDelete')
                        }}
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="primary" @click="deleteDialogs[item.id] = false" data-cy="cancelButton" text>
                          {{ $t('news.showNews.newsList.delete.cancel') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          v-if="!item.is_published"
                          @click.stop="
                            deleteDialogs[item.id] = false;
                            $emit('delete', item.id);
                          "
                          data-cy="deleteButton"
                          color="error"
                          text
                        >
                          {{ $t('news.showNews.newsList.delete.deleteNow') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </template>

    <template v-slot:no-data>
      <div class="text-center py-12 my-12">
        <v-icon color="grey" x-large>fal fa-empty-set</v-icon>
      </div>
    </template>
  </v-data-iterator>
</template>

<script>
import { parseISO, isToday, format } from 'date-fns';

export default {
  props: {
    search: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    selected: {
      type: String,
      default: null
    },
    showAdminFeatures: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deleteDialogs: {}
    };
  },
  computed: {
    syncOptions: {
      get() {
        return this.options;
      },
      set(value) {
        this.$emit('options-changed', value);
      }
    },
    listModel: {
      get() {
        return this.selected;
      },
      set(value) {
        this.$emit('selected-changed', value);
      }
    }
  },
  methods: {
    formatDate(date) {
      const isoDate = parseISO(date);
      return format(isoDate, isToday(isoDate) ? 'HH:mm' : 'dd.MM.yyyy');
    },
    translateReceivers(receivers) {
      return receivers.map((receiver) => this.$t(`news.receiver.${receiver.toLowerCase()}`)).join(', ');
    }
  }
};
</script>

<style lang="scss" scoped>
.v-data-iterator {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  ::v-deep {
    > div {
      flex-grow: 1;
    }

    .header,
    .v-data-footer {
      flex-grow: initial !important;
    }
  }

  .header {
    .search {
      background-color: var(--v-white-base);
      -webkit-box-shadow: 0 0 50px -10px rgba(53, 53, 54, 0.25);
      box-shadow: 0 0 50px -10px rgba(53, 53, 54, 0.25);

      ::v-deep {
        .v-input__icon {
          margin-right: 10px;

          .v-icon {
            font-size: 1rem;
          }
        }

        .v-input__slot {
          background: none;
        }
      }

      ::v-deep .v-text-field__slot {
        input {
          font-size: 0.9rem;
          margin-top: 2px;
        }
      }
    }

    .loading {
      margin-top: -4px;
    }
  }

  ::v-deep {
    .v-list-item {
      height: 90px;
      border-left: 3px solid transparent;
      border-bottom: 1px solid var(--v-grey-lighten2);

      &:first-child {
        border-top: 1px solid var(--v-grey-lighten2);
      }

      &.unread {
        border-left: 3px solid var(--v-primary-base);
      }

      .actions {
        margin-top: -5px;
        margin-right: -8px;
        display: none;
      }

      &:hover {
        .datetime {
          display: none;
        }
        .actions {
          display: initial;
        }
      }
    }

    .v-list-item--link:before {
      background-color: transparent;
    }

    .v-list-item--active {
      background-color: var(--v-white-base);

      &::before {
        opacity: 0;
      }
    }

    .v-data-footer {
      justify-content: end;
      padding: 16px;
    }
  }
}

@media (hover: none) {
  ::v-deep {
    .v-list-item {
      .datetime {
        display: initial !important;
      }
      .actions {
        margin-top: -5px;
        margin-right: -8px;
        display: initial !important;
      }
    }
  }
}
</style>

import { format, parseISO } from 'date-fns';

export const formatDate = (date, dateFormat = 'dd.MM.yyyy') => {
  if (!date) return null;

  return format(parseISO(date), dateFormat);
};

export const formatTime = (date, dateFormat = 'HH:mm') => {
  return format(parseISO(date), dateFormat);
};

export const formatDateTime = (date, dateFormat = 'dd.MM.yyyy, HH:mm') => {
  return format(parseISO(date), dateFormat);
};

export const formatNumber = function (value, prefix = '', suffix = '') {
  if (!value || value === '0') {
    return '0';
  }

  let returnValue = '';

  if (value) {
    returnValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  if (prefix !== '') {
    returnValue = prefix + returnValue;
  }

  if (suffix !== '') {
    returnValue = returnValue + suffix;
  }

  return returnValue;
};

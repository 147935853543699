export default (db) => ({
  list() {
    return db.getAll('captures').catch(() => []);
  },
  async save(captures) {
    return Promise.all(captures.map((capture) => db.put('captures', capture)));
  },
  clear() {
    return db.clear('captures');
  }
});

<template>
  <div>
    <mobile-header v-if="this.$auth.check()"></mobile-header>
    <mobile-menu v-if="this.$auth.check()"></mobile-menu>
    <mobile-navigation v-if="this.$auth.check()"></mobile-navigation>
    <content-area>
      <slot />
    </content-area>
  </div>
</template>

<script>
import MobileHeader from '@/shared/modules/Layout/components/MobileHeader';
import MobileMenu from '@/shared/modules/Layout/components/MobileMenu';
import MobileNavigation from '@/shared/modules/Layout/components/MobileNavigation';
import ContentArea from '@/shared/modules/Layout/components/ContentArea';

export default {
  components: {
    MobileHeader,
    MobileMenu,
    MobileNavigation,
    ContentArea
  }
};
</script>

export default {
  login: {
    pleaseSignIn: 'Bitte loggen Sie sich ein',
    badCredentials: 'Benutzername oder Passwort falsch',
    userLocked: 'Benutzer ist gesperrt.',
    offline: 'Für den Login ist eine Internetverbindung erforderlich',
    youAreOffline: 'Bitte verbinden Sie das Gerät zum Einloggen mit dem Internet.',
    username: 'Benutzername',
    password: 'Passwort',
    login: 'Login',
    forgotPassword: 'Passwort vergessen? Kontaktieren Sie bitte Ihren Administrator',
    imprint: 'Impressum',
    privacy: 'Datenschutz',
    maintenanceMode: 'Während Wartungsarbeiten ist ein Login nicht möglich.',
    maintenanceModeIsActive: 'Aufgrund von geplanten Wartungsarbeiten ist der Login vorübergehend nicht möglich.'
  }
};

export default (db) => ({
  list() {
    return db.getAll('lines').catch(() => []);
  },
  get(lineId) {
    return db.get('lines', lineId).catch(() => null);
  },
  async save(lines) {
    if (lines.length === 0) return;

    const tx = db.transaction('lines', 'readwrite');

    lines.forEach((line) => tx.store.put(line));

    await tx.done;

    return true;
  },
  async findBy(branch) {
    return await db.getAllFromIndex('lines', 'branch', branch);
  },
  clear() {
    return db.clear('lines');
  }
});

<template>
  <v-form @submit.prevent="doLogin">
    <styled-snackbar color="error" :value="hasError" icon="fal fa-question-circle" top>
      {{ $t(errorMessage) }}
    </styled-snackbar>
    <div class="text-center pt-6 pb-6">
      <img :src="require('@/shared/assets/logos/kcs.svg')" height="100" alt="KCS Logo" />
    </div>
    <div class="letter-spacing-1 text-center pb-6 text-uppercase grey--text">
      {{ $t('project') }}
    </div>
    <v-alert v-if="isOffline" color="error" class="text-center" text>
      {{ $t('login.youAreOffline') }}
    </v-alert>
    <v-alert v-else-if="maintenanceMode" color="error" class="text-center" text>
      {{ $t('login.maintenanceModeIsActive') }}
    </v-alert>
    <v-text-field
      :label="$t('login.username')"
      v-model="username"
      autocomplete="username"
      required
      data-cy="username"
      autocapitalize="none"
    ></v-text-field>
    <v-text-field
      :label="$t('login.password')"
      v-model="password"
      type="password"
      required
      autocomplete="current-password"
      data-cy="password"
    ></v-text-field>
    <div class="py-6">
      <v-btn class="elevation-0" type="submit" color="primary" :loading="isWorking" rounded block data-cy="loginButton">
        {{ $t('login.login') }}
      </v-btn>
    </div>
    <div class="text-center mt-4 grey--text">
      {{ $t('login.forgotPassword') }}
    </div>
    <v-layout align-center justify-center fill-height class="grey--text mb-12 pb-12">
      <v-flex shrink><imprint></imprint></v-flex>
      <v-flex shrink class="display-1 px-2">&middot;</v-flex>
      <v-flex shrink><privacy></privacy></v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import Imprint from '@/pwa/modules/Login/components/Imprint';
import Privacy from '@/pwa/modules/Login/components/Privacy';

export default {
  props: {
    maintenanceMode: {
      type: Boolean,
      default: false
    },
    isOffline: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      username: '',
      password: '',
      showPassword: false,
      hasError: false,
      errorMessage: 'login.badCredentials',
      isWorking: false,
      showImprint: false
    };
  },
  components: {
    Imprint,
    Privacy
  },
  methods: {
    async doLogin() {
      this.hasError = false;
      this.isWorking = true;

      try {
        await this.$auth.login({
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            username: this.username,
            password: this.password
          },
          redirect: {
            path: '/'
          },
          staySignedIn: true
        });
      } catch (error) {
        if (true === this.maintenanceMode) {
          this.errorMessage = 'login.maintenanceMode';
        } else if (true === this.isOffline) {
          this.errorMessage = 'login.offline';
        } else if (423 === error?.response?.status) {
          this.errorMessage = 'login.userLocked';
        } else {
          this.errorMessage = 'login.badCredentials';
        }
        this.hasError = true;
      } finally {
        this.isWorking = false;
      }
    }
  }
};
</script>

<style scoped>
.v-form {
  max-width: 270px;
}
</style>

<template>
  <v-container v-if="isUnavailable" :class="$vuetify.breakpoint.xs ? 'mobile' : ''" class="offline-message" fill-height>
    <v-layout wrap>
      <v-flex xs12 class="align-self-center">
        <v-card-text class="title text-center">
          <span v-if="maintenanceMode">{{ $t('news.maintenanceText') }}</span>
          <span v-else>{{ $t('news.offlineText') }}</span>
          <offline-snackbar />
        </v-card-text>
      </v-flex>
    </v-layout>
  </v-container>

  <styled-box v-else :headline="$t('news.showNews.title')" class="styled-box-container">
    <v-row no-gutters class="news-wrapper ma-0">
      <v-col v-if="$vuetify.breakpoint.smAndUp || !this.listSelected" cols="12" sm="3" class="news-list">
        <news-list
          @search-changed="this.setListSearch"
          @options-changed="this.setListOptions"
          @selected-changed="this.setListSelected"
          @edit="this.onEdit"
          @delete="this.onDelete"
          :search="this.listSearch"
          :options="this.listOptions"
          :total="this.listTotal"
          :items="this.listItems"
          :selected="this.listSelected"
          :loading="this.listLoading"
          :show-admin-features="this.showAdminFeatures"
        />
      </v-col>
      <v-col v-if="$vuetify.breakpoint.smAndUp || !!this.listSelected" cols="12" sm="9" class="news-content">
        <news-content
          @status-changed="onStatusChanged"
          :data="this.details"
          :data-loading="this.dataLoading"
          :status-loading="this.statusLoading"
          :show-admin-features="this.showAdminFeatures"
        />
        <v-btn
          v-if="this.showAdminFeatures && !this.$vuetify.breakpoint.xsOnly"
          :to="{ name: 'news-create' }"
          color="secondary"
          class="ma-12"
          data-cy="createButton"
          x-large
          fixed
          bottom
          right
          fab
        >
          <v-icon>fal fa-plus</v-icon>
        </v-btn>
        <div
          v-if="$vuetify.breakpoint.xsOnly"
          @click="() => this.setListSelected(null)"
          class="back-button white px-4 my-4"
        >
          <v-icon>fal fa-arrow-left</v-icon>
        </div>
      </v-col>
    </v-row>
  </styled-box>
</template>

<script>
import UnavailableMixin from '@/pwa/modules/Layout/components/UnavailableMixin.vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import {
  NEWS_NAMESPACE,
  FETCH_NEWS_LIST,
  FETCH_NEWS_DETAILS,
  PUBLISH_SELECTED_NEWS,
  UNPUBLISH_SELECTED_NEWS,
  DELETE_NEWS
} from '@/shared/modules/News/store';
import OfflineSnackbar from '@/shared/components/OfflineSnackbar';
import NewsList from '@/shared/modules/News/components/NewsList';
import NewsContent from '@/shared/modules/News/components/NewsContent';
import { authUser } from '@/shared/helper/vue-auth';

export default {
  mixins: [UnavailableMixin],
  components: {
    OfflineSnackbar,
    NewsList,
    NewsContent
  },
  data() {
    return {
      listLoading: false,
      dataLoading: false,
      statusLoading: false
    };
  },
  computed: {
    ...mapState(NEWS_NAMESPACE, ['listSearch', 'listOptions', 'listItems', 'listTotal', 'listSelected', 'details']),
    showAdminFeatures() {
      return authUser(this.$auth, 'roles').some((role) =>
        ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_SYSTEM_ADMINISTRATOR'].includes(role)
      );
    }
  },
  methods: {
    ...mapMutations(NEWS_NAMESPACE, ['setListSearch', 'setListOptions', 'setListSelected', 'setDetails']),
    ...mapActions(NEWS_NAMESPACE, {
      fetchList: FETCH_NEWS_LIST,
      fetchDetails: FETCH_NEWS_DETAILS,
      publishNews: PUBLISH_SELECTED_NEWS,
      unpublishNews: UNPUBLISH_SELECTED_NEWS,
      deleteNews: DELETE_NEWS
    }),
    async onStatusChanged(status) {
      this.statusLoading = true;
      if (true === status) {
        await this.publishNews();
      } else {
        await this.unpublishNews();
      }
      this.statusLoading = false;
    },
    async onEdit(newsId) {
      await this.$router.push({ name: 'news-edit', params: { newsId } });
    },
    async onDelete(newsId) {
      this.listLoading = true;
      await this.deleteNews(newsId);
      this.listLoading = false;
    }
  },
  watch: {
    async listItems(listItems) {
      await this.setListSelected(this.$vuetify.breakpoint.xsOnly ? null : listItems[0] ? listItems[0].id : null);
    },
    async listSearch() {
      this.listLoading = true;
      await this.fetchList();
      this.listLoading = false;
    },
    async listOptions() {
      this.listLoading = true;
      await this.fetchList();
      this.listLoading = false;
    },
    async listSelected(newsId) {
      await this.setDetails(null);
      if (null !== newsId) {
        this.dataLoading = true;
        await this.fetchDetails();
        this.dataLoading = false;
      }
    }
  },
  async mounted() {
    if (0 === this.listItems.length) {
      this.listLoading = true;
      await this.fetchList();
      this.listLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.styled-box-container {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;

  :deep(.box) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }
}
.offline-message.mobile {
  color: var(--v-white-base);
  background: url('~@/shared/assets/background-menu.png') no-repeat bottom center;
  background-size: cover;
}
.news-wrapper {
  min-height: 100%;

  .news-list {
    background-color: #f2f5f8;
  }

  .news-content {
    background-color: #fff;
    -webkit-box-shadow: 0 0 50px 0 rgba(53, 53, 54, 0.25);
    box-shadow: 0 0 50px 0 rgba(53, 53, 54, 0.25);
  }
}
.back-button {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .styled-box-container {
    padding: 16px;

    :deep(.box) {
      padding-top: 0 !important;
    }
  }
}
</style>

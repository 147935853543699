export default (db) => ({
  list() {
    return db.getAll('branches').catch(() => []);
  },
  async save(branches) {
    await db.clear('branches');

    return Promise.all(branches.map((branche) => db.put('branches', branche)));
  },
  async saveOne(branche) {
    return db.put('branches', branche);
  },
  clear() {
    return db.clear('branches');
  }
});

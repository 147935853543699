<template>
  <dialog-select
    v-model="selected"
    :label="$t('common.stop').toUpperCase()"
    :name="fieldName"
    :items="list"
    :required="required"
    :disabled="disabled"
    :rules="requiredRule"
    @open="scrollToNearest"
    prepend-inner-icon="fal fa-store-alt"
    append-icon="fal fa-chevron-down"
    item-text="title"
    item-value="id"
    :data-cy="fieldName"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content
        :class="{
          gps: item.neighbor || item.nearest,
          nearest: item.nearest
        }"
      >
        <v-layout row align-center>
          <v-flex :class="item.nearest || item.neighbor ? 'xs10' : 'xs12'" class="px-4">
            <div class="text-truncate">{{ item.title }}</div>
          </v-flex>
          <v-flex xs2 v-if="item.nearest || item.neighbor" class="text-right">
            <v-icon color="accent" class="mr-4" v-if="item.nearest">fal fa-map-marker-alt </v-icon>
            <v-icon color="accent lighten-3" class="mr-4" v-if="item.neighbor">fal fa-map-marker </v-icon>
          </v-flex>
        </v-layout>
      </v-list-item-content>
    </template>
  </dialog-select>
</template>

<script>
import { defaultIfEmpty, mergeMap, map, pluck, tap, combineLatest } from 'rxjs/operators';

export default {
  name: 'StopSelect',
  props: {
    value: {
      type: String
    },
    line: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      default: 'stop-select'
    },
    destinationStopId: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      requiredRule: [
        (v) => (this.required && !!v) || this.$t('validations.required', { field: this.$t('common.stop') })
      ]
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    scrollToNearest() {
      setTimeout(function () {
        if (document.getElementsByClassName('gps').length > 0) {
          document.getElementsByClassName('gps')[0].scrollIntoView();
        }
      }, 250);
    }
  },
  subscriptions() {
    return {
      list: this.$watchAsObservable('line', { immediate: true }).pipe(
        pluck('newValue'),
        mergeMap((line) => this.$lineService.findById(line)),
        defaultIfEmpty({}),
        map((line) => line.route || []),
        map((route) =>
          route
            .map((stopToOrder) => ({
              ...stopToOrder.stop,
              order: stopToOrder.order
            }))
            .sort((a, b) => a.order - b.order)
        ),
        map((stops) => this.$currentLocationService.getNearestStationByStops(stops, 2, false)),
        combineLatest(
          this.$watchAsObservable('destinationStopId', {
            immediate: true
          }).pipe(pluck('newValue'))
        ),
        map(([list, destination]) => {
          if (!!list && !!list[0] && destination === list[0].id) {
            return list.reverse();
          }

          return list;
        }),
        tap((route) => this.$emit('onRoute', route))
      )
    };
  }
};
</script>

<style scoped>
.gps {
  margin-right: -16px;
  padding-right: 16px;
  border-right: 3px solid var(--v-accent-lighten3);
}
.gps.nearest {
  border-right: 3px solid var(--v-accent-base);
}
.v-list-item__content {
  font-size: 1rem;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.onVisibilityChange),expression:"onVisibilityChange"}],staticClass:"v-picker v-card v-picker--time theme--light",on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('div',{staticClass:"v-picker__title picker-header"},[_c('div',{staticClass:"v-time-picker-title"},[_c('div',{staticClass:"v-time-picker-title__time"},[_c('div',{staticClass:"display-3 font-weight-medium"},[_vm._v(_vm._s(_vm.value))])])])]),_c('div',{staticClass:"v-picker__body theme--light",staticStyle:{"width":"290px"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_c('ul',{staticClass:"picker-hour v-date-picker-years"},_vm._l((24),function(count){return _c('li',{key:count,staticClass:"picker-entry",class:{
                'active primary--text active-hour': String(count - 1).padStart(2, '0') === _vm.hour,
                disabled: _vm.isHourDisabled(count - 1)
              },on:{"click":function($event){return _vm.onClickHour(count - 1)}}},[_vm._v(" "+_vm._s(String(count - 1).padStart(2, '0'))+" ")])}),0)])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_c('ul',{staticClass:"picker-minute v-date-picker-years"},_vm._l((60),function(count){return _c('li',{key:count,staticClass:"picker-entry",class:{
                'active primary--text active-minute': String(count - 1).padStart(2, '0') === _vm.minute,
                disabled: _vm.isMinuteDisabled(count - 1)
              },on:{"click":function($event){return _vm.onClickMinute(count - 1)}}},[_vm._v(" "+_vm._s(String(count - 1).padStart(2, '0'))+" ")])}),0)])]),_c('v-col',{staticClass:"text-right pt-6 pb-4 px-6",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"px-9",attrs:{"color":"primary","depressed":"","rounded":""},on:{"click":function($event){return _vm.$emit('ok')}}},[_vm._v(" "+_vm._s(_vm.$t('common.ok'))+" ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <button-group
    v-if="list && list.length >= 2"
    v-model="selected"
    :options="options()"
    :disabled="disabled"
    headline="headline.direction"
    data-cy="directionSelect"
  ></button-group>
</template>

<script>
import ButtonGroup from '@/pwa/modules/Capture/components/Fields/ButtonGroup.vue';

import { pluck, mergeMap, defaultIfEmpty, map, tap, delay } from 'rxjs/operators';

export default {
  components: { ButtonGroup },
  name: 'DirectionSelect',
  data() {
    return {
      showDirections: false
    };
  },
  props: {
    value: {
      type: String
    },
    line: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    options() {
      if (this.showDirections) {
        return this.list || [];
      }

      return [
        { label: '', value: null },
        { label: '', value: null }
      ];
    }
  },
  subscriptions() {
    return {
      list: this.$watchAsObservable('line', { immediate: true }).pipe(
        pluck('newValue'),
        mergeMap((line) => this.$lineService.findById(line)),
        defaultIfEmpty({}),
        map((line) => line.route || []),
        map((route) =>
          route
            .map((stopToOrder) => ({
              ...stopToOrder.stop,
              order: stopToOrder.order
            }))
            .sort((a, b) => {
              return a.order - b.order;
            })
        ),
        map((list) => {
          if (list.length < 2) return [];

          return [list[0], list[list.length - 1]];
        }),
        map((directions) => directions.map((item) => ({ label: item.title, value: item.id }))),
        tap(() => {
          this.showDirections = false;
        }),
        delay(10),
        tap(() => {
          this.showDirections = true;
        })
      )
    };
  }
};
</script>

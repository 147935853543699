<template>
  <div v-if="dataLoading" class="text-center py-12 my-12">
    <v-progress-circular color="primary" indeterminate />
  </div>
  <v-col v-else-if="data" :class="!$vuetify.breakpoint.xsOnly ? 'pa-9' : 'pa-6'">
    <span v-if="!$vuetify.breakpoint.xsOnly">
      <v-row v-if="showAdminFeatures">
        <v-col class="pt-0">
          <div>
            <span class="grey--text text-subtitle-2 font-weight-regular mr-3">
              {{ $t('news.showNews.newsContent.visibleFor') }}
            </span>
            <div class="receivers text-subtitle-2 font-weight-regular d-inline-block" data-cy="content-receivers">
              {{ translateReceivers(data.receivers) }}
            </div>
          </div>
        </v-col>
        <v-col cols="auto" class="status-text grey--text text-caption text-uppercase pt-0">
          <span v-if="!statusLoading" data-cy="content-status">
            {{ data.is_published ? $t('news.active') : $t('news.inactive') }}
          </span>
          <v-progress-circular
            v-else
            color="primary"
            size="19"
            width="2"
            data-cy="content-statusProgress"
            indeterminate
          />
        </v-col>
        <v-col cols="auto" class="status-switch pt-0 pl-0">
          <v-switch v-model="switchModel" hide-details></v-switch>
        </v-col>
      </v-row>
      <v-row v-if="showAdminFeatures">
        <v-col class="text-right grey--text text-caption py-0" data-cy="content-readings">
          {{ data.readings }} {{ $t('news.showNews.newsContent.readings') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="meta-info grey--text text-uppercase text-caption py-0">
          {{ formatDate(data.date) }}, <span data-cy="content-publisher">{{ data.publisher }}</span>
        </v-col>
      </v-row>
    </span>
    <span v-else>
      <v-row>
        <v-col cols="auto" class="pt-0">
          <v-avatar color="primary">
            <span class="white--text font-weight-medium">{{ data.publisher }}</span>
          </v-avatar>
        </v-col>
        <v-col class="pt-0 pl-0">
          <div class="font-weight-medium" data-cy="content-publisher">{{ data.publisher }}</div>
          <div>
            {{ $t('news.to') }}
            <span class="font-weight-medium"
              >{{ $t('news.showNews.newsContent.you') }}
              {{ data.publisher.length - 1 > 0 ? `+${data.publisher.length}` : '' }}</span
            >
          </div>
        </v-col>
        <v-col cols="auto" class="grey--text text-caption text-right pt-0">
          {{ formatMobileDate(data.date) }}
        </v-col>
      </v-row>
      <div class="mt-2 mb-4">
        <v-divider />
      </div>
    </span>
    <v-row>
      <v-col class="pt-0">
        <div class="text-h4 mb-6" data-cy="content-subject">{{ data.subject }}</div>
        <div v-html="data.content" class="content" data-cy="content-content"></div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { parseISO, isToday, format } from 'date-fns';

export default {
  props: {
    data: {
      type: Object,
      default: null
    },
    dataLoading: {
      type: Boolean,
      default: false
    },
    statusLoading: {
      type: Boolean,
      default: false
    },
    showAdminFeatures: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    switchModel: {
      get() {
        return this.data.is_published;
      },
      set(value) {
        this.$emit('status-changed', value);
      }
    }
  },
  methods: {
    formatDate(date) {
      const isoDate = parseISO(date);
      return `${format(isoDate, 'dd.MM.yyyy')}, ${this.$t('news.showNews.newsContent.at')} ${format(
        isoDate,
        'HH:mm'
      )} Uhr`;
    },
    formatMobileDate(date) {
      const isoDate = parseISO(date);
      return isToday(isoDate) ? format(isoDate, 'HH:mm') : format(isoDate, 'dd.MM.yyyy HH:mm');
    },
    translateReceivers(receivers) {
      return receivers.map((receiver) => this.$t(`news.receiver.${receiver.toLowerCase()}`)).join(', ');
    }
  }
};
</script>

<style lang="scss" scoped>
.receivers {
  border-bottom: 1px solid var(--v-grey-lighten2);
}
.status-text {
  letter-spacing: 0.15rem !important;
}
.status-switch {
  .v-input {
    margin-top: -2px;
    padding-top: 0;
  }
}
.meta-info {
  letter-spacing: 0.15rem !important;
}
.content {
  :deep(img) {
    max-width: 100% !important;
    height: auto !important;
  }
}
</style>
